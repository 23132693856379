import React from 'react';
import {
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import { Space, Button, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { deleteBlog } from '../../api';

export const blog_columns = [
    {
        title: 'Title',
        key: 'title',
        dataIndex: 'title',
        render: (text) => <p style={{ textTransform: 'capitalize' }}>{text}</p>
    },
    {
        title: 'Category',
        key: 'category',
        dataIndex: 'category'
    },
    {
        title: 'Excerpt',
        key: 'excerpt',
        dataIndex: 'excerpt'
    },
    {
        title: 'Action',
        key: 'action',
        render: (props) => {
            const { slug, edit_url } = props;

            function showPromiseConfirm() {
                Modal.confirm({
                  title: 'Do you want to delete blog?',
                  icon: <ExclamationCircleOutlined />,
                  content: `This will delete the blog ${props.title}`,
                  onOk() {
                    return new Promise((resolve, reject) => {
                        deleteBlog([props._id]).then(res => {
                            if (res.status === 200) {
                                window.location.reload();
                            } else {
                                throw new Error('status not handled');
                            }
                        }).catch(error => {
                            console.error(error);
                        })
                    })
                    
                    
                  },
                  onCancel() {},
                });
              }

            return (
                <Space>
                    <Link to={location => ({ ...location, pathname: edit_url , search: `ref=${slug}`})}>
                        <Button 
                            icon={<EditOutlined />}
                            type="primary" 
                            stlye={{ width: '128px'}}
                        >
                            Edit
                        </Button>
                    </Link>
                    <Button 
                        type="ghost" 
                        danger
                        icon={<DeleteOutlined />}
                        onClick={showPromiseConfirm}
                    >
                        Delete
                    </Button>
                </Space>
            )
        }
    }
];
