import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import {
    projectColumns
} from './fixtures';

import { getProjects } from '../../api/projects';

const ProjectList = () => {

    const [data, setData] = useState();
    const [totalSize, setTotalSize] = useState();
    const [pageSize, setPageSize] = useState();
    const [loading, setLoading] = useState();
    const [current, setCurrent] = useState();

    useEffect(() => {
        _getProjects({ page: 1, limit: 10 });
    }, []);

    function _getProjects(payload) {
        setLoading(true);
        getProjects(payload)
            .then(res => {
                if(res.status === 200) {
                    const data = res.data;
                    const parsedData = data.map(({ data: d }) => ({
                        _id: d._id,
                        key: d._id,
                        project_name: d.title || '',
                        slug: d.slug || '',
                        project_type: d.property_type,
                        price: {
                            minPrice: d.minimum_price,
                            maxPrice: d.maximum_price
                        },
                        locality: (d.locality || {}).locality,
                        city: (d.locality || {}).city,
                        construction_status: ''
                    }))
                    setData(parsedData);
                    setPageSize(payload.limit);
                    setTotalSize(res.count);
                    setCurrent(payload.page)
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            })
    }

    function onTableChange(pagination) {
        _getProjects({ page: pagination.current, limit: pagination.pageSize });
    }

    return (
        <Table
            columns={projectColumns}
            dataSource={data}
            loading={loading}
            onChange={onTableChange}
            pagination={{ current: current, pageSize, total: totalSize }}
        />
    )
}

export default ProjectList;
