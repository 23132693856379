import {
    FolderAddOutlined
} from '@ant-design/icons';
import React from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    useHistory
} from 'react-router-dom';
import { Typography, Row, Button, Space, Col, Divider } from 'antd'

import { PageContainer } from '../../components';

import { EditJob, JobsList } from './frames';

const Project = () => {
    const { path } = useRouteMatch();
    const history = useHistory();

    if (!localStorage.getItem('token')) {
        history.push('/login');
    }

    return (
        <PageContainer>
            <div style={{ background: '#fff' }}>
                <Row gutter={[0, 0]}>
                    <Col offset="0" span="22" style={{ marginLeft: 16 }}>
                        <Typography.Title level={1}> Jobs </Typography.Title>
                    </Col>
                </Row>
                <Space
                    size="small"
                    style={{
                        margin: '0 1.2rem 0 1.2rem',
                        height: 64,
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                    align="center">
                    <Button 
                        onClick={() => {
                            history.push('/jobs');
                        }}
                    >
                        List Jobs
                    </Button>
                    <div>
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => {
                                    history.push('/jobs/edit?ref=new');
                                    window.location.reload();
                                }} 
                                icon={<FolderAddOutlined />}
                            >Add Jobs</Button>
                        </Space>
                    </div>
                </Space>
            </div>
            <Divider style={{ borderTop: '1px solid #eee', margin: 0 }}/>
            <div style={{ margin: '1rem 1rem' }}>
                <Switch>
                    <Route path={`${path}/edit`}>
                        <EditJob />
                    </Route>
                    <Route exact path={`${path}`}>
                        <JobsList />
                    </Route>
                </Switch>
            </div>
        </PageContainer>
    )
};

export default Project;
