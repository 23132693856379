import React from 'react';
import {
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import { Space, Button, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { deleteJobs } from '../../api';

export const job_columns = [
    {
        title: 'Title',
        key: 'title',
        dataIndex: 'title',
        render: (text) => <p style={{ textTransform: 'capitalize' }}>{text}</p>
    },
    {
        title: 'Category',
        key: 'category',
        dataIndex: 'category'
    },
    {
        title: 'Action',
        key: 'action',
        render: (props) => {
            const { _id, edit_url } = props;

            function showPromiseConfirm() {
                Modal.confirm({
                  title: 'Do you want to delete this Job?',
                  icon: <ExclamationCircleOutlined />,
                  content: `This will delete the opening ${props.title}. This will delete all associated applications with this jobID. If
                  you want this job to not show up on the website use unpublish instead.`,
                  onOk() {
                    return new Promise((resolve, reject) => {
                        deleteJobs([props._id]).then(res => {
                            if (res.status === 200) {
                                window.location.reload();
                            } else {
                                throw new Error('status not handled');
                            }
                        }).catch(error => {
                            console.error(error);
                        })
                    })
                    
                    
                  },
                  onCancel() {},
                });
              }

            return (
                <Space>
                    <Link to={location => ({ ...location, pathname: edit_url , search: `ref=${_id}`})}>
                        <Button 
                            icon={<EditOutlined />}
                            type="primary" 
                            stlye={{ width: '128px'}}
                        >
                            Edit
                        </Button>
                    </Link>
                    {/* <Button 
                        type="ghost" 
                        danger
                        icon={<DeleteOutlined />}
                        onClick={showPromiseConfirm}
                    >
                        Delete
                    </Button> */}
                </Space>
            )
        }
    }
];
