import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Modal } from 'antd';
import { 
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';

import { getUsers } from '../../api';
import EditBuilder from './components/EditUser';

const ActionSetup = (props) => {

    const [open, setOpen] = useState(false);

    function onClose() {
        setOpen(false);
    }

    function handleOpen() {
        setOpen(true);
    }

    function showPromiseConfirm() {
        Modal.confirm({
          title: 'Do you want to delete user?',
          icon: <ExclamationCircleOutlined />,
          content: `This will delete the user ${props.email}.`,
          onOk() {
            // return deleteLocality([props._id]);
          },
          onCancel() {},
        });
      }

    return (
        <>
            <Space>
                <Button
                    icon={<EditOutlined />}
                    type="primary" 
                    stlye={{ width: '128px'}}
                    onClick={handleOpen}
                >
                    Edit
                </Button>

                <Button
                    type="ghost"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={showPromiseConfirm}
                >
                    Delete
                </Button>
            </Space>
            {
                open && (
                    <EditBuilder
                        open={open}
                        onClose={onClose}
                        title={"Edit Builder"}
                        value={props}
                        builders={props.builders}
                        slug={props.slug}
                        showAlert={true}
                    />
                )
            }
        </>
    );
}

const BuildersList = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
         getUsers()
             .then(res => {
                 if (res.status === 200) {
                     setData(res.data)
                 }
             })
             .catch(console.log)
             .finally(() => {
                 setLoading(false);
             })
 
    }, []);

    const builderColumn = [
        {
            title: 'email',
            key: 'builder',
            dataIndex: 'email'
        },
        // {
        //     title: 'Action',
        //     key: 'action',
        //     render: (props) => {
        //         return (
        //             <ActionSetup 
        //                 { ...props}
        //                 builders={data}
        //                 slug={props.slug} 
        //             />
        //         )
        //     }
        // }
    ];

    return (
        <>
            <Table
                columns={builderColumn}
                dataSource={data}
                loading={loading}
            />
            <EditBuilder 
                open={props.openEdit}
                onClose={props.closeEdit}
                title="Add New Builder"
                builders={data}
            />
        </>
    );
}

export default BuildersList;
