import React, { useEffect, useState } from 'react';
import {
    Menu,
    Layout,
    Image,
    Divider,
    Avatar,
    Row,
    Space,
    Typography,
    Dropdown
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import jwt from 'jsonwebtoken';

import './PageContainer.scss';

const MenuContainer = () => {

    const history = useHistory();
    const location = useLocation();

    const token = (localStorage.getItem('token') || '').replace('Bearer ', '');
    const user = jwt.decode(token);
    const role = (user || {}).role;

    let pathname = location.pathname
    const s2 = pathname.indexOf('/', 1);

    if (s2 > -1) {
        pathname = pathname.substring(0, s2);
    }

    return (
        <Menu
            style={{ height: "100%" }}
            selectedKeys={items.filter(item => item.link === pathname).map(item => item.name)}
            defaultOpenKeys={['sub1']}
            mode="inline"
            theme="light"
        >
            {
                items.filter(item => {
                    if (item.protectedRole && token) {
                        if (role[item.protectedRole]) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                }).map((item, index) => {
                    if (item.type === "Menu.Item") {
                        return (
                            <Menu.Item key={item.name} onClick={() => history.push(item.link)}>
                                {item.name}
                            </Menu.Item>
                        )
                    }
                    if (item.type === 'Divider') {
                        return <React.Fragment key={index}>
                                   <Divider />
                               </React.Fragment>;
                    }

                    return null;
                })
            }
        </Menu>
    )
}

const ProfileMenu = () => {
    const handleClick = (e) => {
        if (e.key === 'logout') {
            localStorage.clear();
            window.location.reload();
        }
    }

    return (
        <Menu onClick={handleClick}>
            <Menu.Item key="logout">Log Out</Menu.Item>
        </Menu>
    );
}

const PageContainer = (props) => {
    const username = localStorage.getItem('username') || 'Admin';

    return (
        <Layout className="page-container">
            <Layout.Header
                className="page-container__header"
            >
                <Image
                    width="138px"
                    src="/investo-xpert-logo.png"
                />
                <Space size="middle">
                    <Typography.Text strong style={{ fontSize: 18 }}>{username}</Typography.Text>
                    <Dropdown overlay={ProfileMenu} arrow>
                        <Avatar size={40} icon={<UserOutlined />} style={{ cursor: 'pointer' }} />
                    </Dropdown>
                </Space>
            </Layout.Header>
            <Layout className="page-container__body">
                <Layout.Sider>
                    <MenuContainer />
                </Layout.Sider>
                <Layout className="body__frame">
                    <Layout.Content>
                        {props.children}
                    </Layout.Content>
                    <Layout.Footer className="body__footer">
                        <Row justify="center">
                            InvestoXpert ©2020
                        </Row>
                    </Layout.Footer>
                </Layout>
            </Layout>
        </Layout>
    );
};

const items = [
    {
        name: 'Users Profiles',
        type: 'Menu.Item',
        link: '/users'
    },
    {
        type: "Divider"
    },
    {
        name: 'Projects',
        type: 'Menu.Item',
        link: '/projects'
    },
    {
        name: 'Properties',
        type: 'Menu.Item',
        link: '/properties'
    },
    {
        name: 'Cities & Localities',
        type: 'Menu.Item',
        link: '/cities-and-localities'
    },
    {
        name: 'Amenities',
        type: 'Menu.Item',
        link: '/amenities'
    },
    {
        name: 'Builders',
        type: 'Menu.Item',
        link: '/builders'
    },
    {
        type: "Divider"
    },
    {
        name: 'Carrers',
        type: 'Menu.Item',
        link: '/jobs',
    },
    {
        name: 'Leads',
        type: 'Menu.Item',
        link: '/leads',
        protectedRole: 'lead_management'
    },
    {
        type: "Divider"
    },
    {
        name: 'Blogs',
        type: 'Menu.Item',
        link: '/blogs'
    },
    {
        type: "Divider"
    }
];

export default PageContainer;