import React, { useState } from 'react';
import {
    Card,
    Form,
    Button,
    Alert,
    Checkbox,
    Row,
    Col
} from 'antd';

import {
    AccordionStep,
} from '../../../../../components';
import {
    form,
    banks
} from '../../../../../assets/config';
import {
    useFormProcess
} from '../../../../../hooks';

const ProjectBanks = React.memo(({
    loading,
    value,
    fns,
    postProject,
    builders,
    ...props
}) => {

    const [formProcess, formProcessFns] = useFormProcess();

    const onSubmit = () => {
        postProject(formProcessFns);
    }

    return (
        <AccordionStep {...props} displayName="Banking Information">
            <Card loading={loading}>
                <Form {...form.layout}>
                    <Form.Item label="EMI Banking Options">
                        <Checkbox.Group
                            value={value}
                            onChange={fns.setBanks}
                        >
                            <Row>
                                {banks.map(bank => (
                                    <Col xl={12}>
                                        <Checkbox value={bank}>{bank}</Checkbox>
                                    </Col>
                                ))}
                            </Row>

                        </Checkbox.Group>
                    </Form.Item>

                    <Form.Item {...form.tailLayout}>
                        <Button 
                            type="primary"
                            htmlType="submit"
                            loading={formProcess.loading}
                            onClick={onSubmit}
                        >
                            Save
                        </Button>
                    </Form.Item>
                    <Form.Item {...form.tailLayout}>
                        {formProcess.errorMsg && (
                            <Alert message={formProcess.errorMsg} type="error" />
                        )}
                    </Form.Item>
                </Form>
            </Card>
        </AccordionStep>
    )
});

function useProjectBanks() {

    const [value, setValue] = useState([]);

    function updateDataFromAPI(data) {
        setValue(data.banks || []);
    }

    function setBanks(value) {
        setValue(value);
    }

    function formatData() {
        return { banks: value };
    }

    return [
        value,
        {
            updateDataFromAPI,
            formatData,
            setBanks
        }
    ];
}

ProjectBanks.useProjectBanks = useProjectBanks;

export default ProjectBanks;
