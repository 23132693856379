import React, { useState, useEffect, createRef } from 'react';
import moment from 'moment';
import {
    Drawer,
    Button,
    Form,
    Input,
    DatePicker,
    AutoComplete,
    Spin,
    Alert
} from 'antd';

import {
    RichTextInput,
    Upload
} from '../../../../../components';
// import { updateBuilder, createNewBuilder, getBuilders } from '../../../api';

const about = createRef();

const EditLocality = ({ 
    open,
    onClose,
    title = "Add / Edit Builder",
    builders,
    slug
}) => {
    const [form] = Form.useForm();
    const [errorMsg, setErrorMsg] = useState();
    const [loading, setLoading] = useState(false);
    const [aboutValue, setAbout] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const builder = builders.filter(d => d.slug === slug)[0] || {};

    const [fileList, setFileList] = useState(builder.image || []);
 
    useEffect(() => {
        if (slug) {
            setLoading(true);
            // getBuilders({ slug })
            //     .then(res => {
            //         if(res.status === 200) {
            //             const _builder = res.data[0] || {};
            //             form.setFieldsValue({
            //                 name: _builder.name,
            //                 established: moment(_builder.established, 'yyyy')
            //             });
            //             about.current = _builder.about || '';
            //             setAbout(_builder.about);
            //             setFileList(_builder.image || []);
            //         }
            //     }).catch(() => {
    
            //     }).finally(() => {
            //         setLoading(false);
            //     })
        }
    }, [slug]);

    const onSubmit = async () => {
        try {
            const values = await form.validateFields();

            const image = ((fileList[0] || {}).response || {});

            if (!slug) {
                setErrorMsg('');
                setSubmitting(true);
                // createNewBuilder({
                //     established: values.established.format('yyyy'),
                //     name: values.name,
                //     image,
                //     about: about.current
                // }).then(res => {
                //     if (res.status === 200) {
                //         onClose();
                //     } else {
                //         throw new Error('status not handled');
                //     }
                // }).catch(error => {
                //     setErrorMsg('Please try again');
                // }).finally(() => {
                //     setSubmitting(false);
                // })
            } else {
                setErrorMsg('');
                setSubmitting(true);
                // updateBuilder({
                //     established: values.established.format('yyyy'),
                //     name: values.name,
                //     image,
                //     about: about.current
                // }, slug).then(res => {
                //     if (res.status === 200) {
                //         onClose();
                //     } else {
                //         throw new Error('status not handled');
                //     }
                // }).catch(error => {
                //     setErrorMsg('Please try again');
                // }).finally(() => {
                //     setSubmitting(false);
                // })
            }
            
            
        } catch(error) {
            console.log(error);
        }
    }

    return (
        <>
            <Drawer
                title={title}
                placement="right"
                closable={true}
                onClose={onClose}
                visible={open}
                width="500"
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button
                            type="primary"
                            style={{ marginRight: 8 }}
                            onClick={onSubmit}
                            loading={submitting}
                        >
                            Save
                        </Button>
                        <Button
                            onClick={onClose}
                        >
                            Close
                        </Button>
                    </div>
                }
            >
                <Spin spinning={loading}>
                 <Form layout="vertical" form={form}>
                    <Form.Item
                        name="Name"
                        label="Name"
                        hasFeedback
                        rules={[{ required: true }]} 
                        >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="Phone Number"
                        label="Phone Number"
                        hasFeedback
                        rules={[{ required: true }]} 
                    >
                         <Input />
                     </Form.Item>
                     { errorMsg && (
                         <Alert 
                            message="Error!"
                            description={errorMsg}
                            type="error"
                         />
                     )}
                 </Form>
                </Spin>
            </Drawer>
        </>
    );
}

export default EditLocality;
