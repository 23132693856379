import { get, post, _delete } from '../../../assets/api';

const getProperties = (data) => {
    return new Promise((resolve, reject) => {
        get('/api/v1/cms/properties', data, { auth: true })
            .then(async res => {
                if (res.status === 200) {
                    const data = await res.json();

                    resolve({
                        status: 200,
                        data: data.result,
                        count: data.count
                    });
                } else {
                    throw new Error('Status not handled');
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

const getProperty = (slug, type) => {
    return new Promise((resolve, reject) => {
        get(`/api/v1/cms/properties/${slug}`, { type }, { auth: true })
            .then(async res => {
                if (res.status === 200) {
                    const data = await res.json() || {};
                    resolve({
                        status: 200,
                        data: {
                            ...data,
                            locality: (data.locality || {})._id,
                            city: (data.locality || {}).city
                        }
                    })
                } else {
                    throw new Error('Status not handled');
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

const updateProperty = (id, data, type) => {

    let endpoint = '/api/v1/cms/properties';

    if (id) {
        endpoint = endpoint + '/' + id;
    }

    endpoint += '?type=' + type;

    return new Promise((resolve, reject) => {
        post(endpoint, data, { auth: true })
            .then(async res => {
                if (res.status === 200 || res.status === 201) {
                    const data = await res.json();
                    resolve({
                        status: 200,
                        _id: ((data || {}).data || {})._id,
                        slug: ((data || {}).data || {}).slug
                    })
                    return;
                } else if (res.status === 409) {
                    const data = await res.json();
                    resolve({
                        status: 409,
                        msg: data.msg || data.error || 'Please Try Again!'
                    })
                    return;
                } else if (res.status === 500) {
                    const data = await res.json();
                    resolve({
                        status: 500,
                        msg: data.msg || data.error || 'Please Try Again'
                    })
                } else {
                    throw new Error('Status not handled');
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

const removeProperties = (ids) => {
    return new Promise((resolve, reject) => {
        _delete('/api/v1/cms/properties', ids, { auth: true })
            .then(async res => {
                if (res.status === 200) {
                    resolve({ 200: true });
                    window.location.reload();
                } else {
                    throw new Error('API Error');
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            })
    })
}

export {
    getProperties,
    getProperty,
    updateProperty,
    removeProperties
}
