import React, { useState, useEffect } from 'react';
import {
    Card,
    Cascader,
    Space,
    Button
} from 'antd';
import { useLocalities } from '../../hooks';
import { useFormProcess } from '../../../../hooks';
import { getMeta, updateMeta } from './components/meta-table/api';
import { getURLsForLocality } from './components/meta-table/fns';
import { MetaTable } from './components';

function getURLs(locality, isCity) {
    const urls = getURLsForLocality(locality);
    let _dataSource = urls.map(url => {
      return {
        key: url.url,
        url: url.url,
        'meta-description': '',
        'title': '',
        'meta-title': ''
      }
    })

    if (isCity) {
        return [{ 
            key: `/real-estate-in-${locality}`,
            url: `/real-estate-in-${locality}`,
            'meta-description': '',
            'title': '', 
            'meta-title': ''
        }, ..._dataSource]
    }

    return _dataSource;  
}  

const Meta = () => {
    const [slug, setSlug] = useState();
    const [isCity, setIsCity] = useState();
    const [data, setData] = useState();
    const [status, fns] = useFormProcess();

    const [cities, localities] = useLocalities();

    const finalObj = localities.reduce((arr, locality) => {
        const city = (locality.city || '').toLowerCase();
        return {
            ...arr,
            [city] : {
                label: locality.city,
                value: city,
                children: {
                    ...((arr[city] || {}).children || {}),
                    [locality.locality]: {
                        label: locality.locality,
                        value: locality.slug
                    }
                }
            }
        }
    }, {});
    
    const finalArr = Object.values(finalObj).map(l => ({ 
        ...l, 
        children: [
            { 
                label: l.label, 
                value: l.value 
            }, 
            ...Object.values(l.children)
        ] 
    }));

    function updateRecord(value, slug, index) {
        return setData(data => {
                return data.map(record => {
                    if (record.url === slug) {
                        return {
                            ...record,
                            [index]: value
                        }
                    }
                return record;
            });
        })
    }

    function handleSave() {
            
            let payload = {
                slug,
                type: isCity ? 'city' : 'locality',
                data: data.map(d => ({
                    meta_description: d['meta-description'] || '',
                    meta_title: d['meta-title'] || '',
                    content: d['content'] || '',
                    slug: d['url'] || ''
                }))
            }

            fns.setLoading(true);
            updateMeta(payload)
                .then(res => {
                    if (res.status === 200) {

                    } else {
                        throw new Error('status not handled');
                    }
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                    fns.setLoading(false);
                })
    }

    useEffect(() => {
        if (slug != undefined) {
            fns.setLoading(true);
            getMeta(slug)
            .then(res => {
                if (res.status === 200) {

                const configObj = {};
                (res.data || []).forEach(d => {
                    configObj[d.slug] = d;
                })

                setData(() => {
                    return getURLs(slug, isCity).map(config => {
                    const value = configObj[config.url];
                    if (value) {
                        return {
                        ...config,
                        'meta-description': value['meta_description'],
                        'meta-title': value['meta_title'],
                        content: value['content'],
                        }
                    } 
                    return config;
                    });
                });
                } else {
                throw new Error(`COM: status not handled`);
                }
            })
            .catch(console.error)
            .finally(() => {
                fns.setLoading(false);
            })
        }
    }, [slug]);

    return (
        <>
            <Card style={{ display: 'flex', justifyContent: 'flex-end'}}>
                <Button type="primary" onClick={handleSave}>Save</Button>
            </Card>
            <Card>
                <Space>
                    City Selector:
                    <Cascader 
                        options={finalArr}  
                        onChange={(slugs) => {
                            setIsCity(slugs[0] == slugs[1]);
                            setSlug(slugs[slugs.length - 1]);
                        }}
                    />
                </Space>
            </Card>
            {
                slug && (
                    <MetaTable 
                        isCity={isCity}
                        updateRecord={updateRecord}
                        data={data}
                        status={status}
                    />
                )
            }
        </>
    )
}

export default Meta;
