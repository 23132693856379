import moment from 'moment';
import { notification } from 'antd';

function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
}

function embedLink(link = '') {
    let l1 = link.indexOf('!2d');
    let l2 = link.indexOf('!3d');
    let l3 = link.indexOf('!', l2 + 4);

    return [link.substring(l1 + 3, l2), link.substring(l2 + 3, l3)];
}

const openSuccess = (message, description) => {
    notification['success']({
     message, description
    });
};

const openError = (message, description) => {
    notification['error']({
     message, description
    });
  };

export {
    disabledDate,
    embedLink,
    openSuccess,
    openError
}
