
import { get, post, _delete } from '../../../assets/api';

const getAmenities = () => {
    return new Promise((resolve, reject) => {
        get('/api/v1/amenity', undefined, { auth: true })
            .then(async res => {
                if (res.status === 200) {
                    const data = await res.json();

                    resolve({
                        status: 200,
                        data: data
                    });
                } else if (res.status === 204) {
                    resolve({
                        status: 200,
                        data: []
                    });
                } else {
                    throw new Error('Status not handled');
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            })
    })
}


const createOrUpdateAmenity = (data, id) => {

    let endpoint = '/api/v1/amenity';

    if (id) {
        endpoint = endpoint + '/' + id;
    }

    return new Promise((resolve, reject) => {
        post(endpoint, data, { auth: true })
            .then(async res => {
                if (res.status === 200 || res.status === 201) {
                    if (!id) {
                        const data = await res.json();
                        resolve({
                            status: 200,
                            _id: data._id
                        })
                        return;
                    }
                    resolve({ status: 200, _id: id });
                    return;
                }
                if (res.status === 400 || res.status === 409) {
                    const data = await res.json();
                    resolve({ status: 400, error: data.msg || 'Error! Please Try Again' });
                    return;
                }

                if (res.status === 500) {
                    const data = await res.json();
                    resolve({ status: 500, error: data.msg || data.error || 'Error! Please Try Again' });
                    return;
                }

                throw new Error('Status not handlde');
            }).catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

const removeAmenities = (ids) => {
    return new Promise((resolve, reject) => {
        _delete('/api/v1/amenity', ids, { auth: true })
            .then(async res => {
                if (res.status === 200) {
                    resolve({ status: 200 });
                } else {
                    throw new Error('API Error');
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            })
    })
}

export {
    getAmenities,
    createOrUpdateAmenity,
    removeAmenities
}
