import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { getLeads } from '../api';
import {
    leadColumns
} from './fixtures';

const LeadsList = () => {

    const [data, setData] = useState();
    const [totalSize, setTotalSize] = useState();
    const [pageSize, setPageSize] = useState();
    const [loading, setLoading] = useState();
    const [current, setCurrent] = useState();

    useEffect(() => {
        _getLeads({ page: 1, limit: 10 });
    }, []);

    function _getLeads(payload) {
        setLoading(true);
        getLeads(payload)
            .then(res => {
                if(res.status === 200) {
                    const data = res.data;
                    const parsedData = data.map((d) => ({
                        _id: d._id,
                        city: d.city,
                        name: d.name,
                        phone: d.phone,
                        email: d.email,
                        project: d.project,
                        visit_location: d.visit_location,
                        created_at: format(parseISO(d.created_at), 'MMM dd, yyyy hh:mm bbb'),
                        city: d.city,
                        remark: d.remark
                    }))
                    setData(parsedData);
                    setPageSize(payload.limit);
                    setTotalSize(res.count);
                    setCurrent(payload.page)
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            })
    }

    function onTableChange(pagination) {
        _getLeads({ page: pagination.current, limit: pagination.pageSize });
    }

    return (
        <Table
            className="leadslist"
            columns={leadColumns}
            scroll={{ x: 2000 }}
            dataSource={data}
            loading={loading}
            onChange={onTableChange}
            pagination={{ current: current, pageSize, total: totalSize }}
        />
    )
}

export default LeadsList;
