import React from 'react';
import { Form, Card, Select } from 'antd';
import { HeartOutlined, StarFilled } from '@ant-design/icons';

import { form } from '../../../../../assets/config';
import { AccordionStep } from '../../../../../components';

const formFields = [
    {
        name: 'bhk_type',
        key: 'bhk_type',
        value: '',
        type: 'radio',
        options: [
            { label: 1, value: 1},
            { label: 2, value: 2},
            { label: 3, value: 3},
            { label: 4, value: 4},
            { label: 5, value: 5}
        ],
        displayName: 'Total BHK'
    },
    {
        name: 'bathrooms',
        key: 'bathrooms',
        value: '',
        type: 'radio',
        options: [
            { label: 1, value: 1},
            { label: 2, value: 2},
            { label: 3, value: 3}
        ],
        displayName: 'Total Bathrooms'
    },
    {
        name: 'balcony',
        key: 'balcony',
        value: '',
        type: 'radio',
        options: [
            { label: 1, value: 1},
            { label: 2, value: 2},
            { label: 3, value: 3}
        ],
        displayName: 'Total Balcony'
    },
    {
        name: 'furnishing',
        key: 'furnishing',
        value: '',
        type: 'radio',
        optionType: 'button',
        options: [
            {label: 'UnFurnished', value: 'UnFurnished'},
            {label: 'Semi Furnished', value: 'Semi Furnished'},
            {label: 'Fully Furnished', value: 'Fully Furnished'}
        ],
        displayName: 'Furnishing'
    },
    {
        name: 'maintainence',
        key: 'maintainence',
        value: null,
        type: 'number',
        displayName: 'Maintainence'
    },
    {
        name: 'built_up_area',
        key: 'built_up_area',
        value: null,
        type: 'number',
        displayName: 'Saleable Area'
    },
    {
        name: 'carpet_area',
        key: 'carpet_area',
        value: null,
        type: 'number',
        displayName: 'Carpet Area'
    },
    {
        name: 'builder',
        key: 'builder',
        value: null,
        type: 'select',
        displayName: 'Builder',
        options: [],
        propertyType: 'resale'
    },
    {
        name: 'facing',
        key: 'facing',
        value: '',
        type: 'radio',
        displayName: 'Facing',
        optionType: 'button',
        options: [
            {label: 'North Facing', value: 'North Facing'},
            {label: 'South Facing', value: 'South Facing'},
            {label: 'East Facing', value: 'East Facing'},
            {label: 'West Facing', value: 'West Facing'}
        ]
    },
    {
        name: 'rating',
        key: 'rating',
        value: null,
        type: 'rate',
        max: 5,
        character: <StarFilled />,
        displayName: 'Rating',
        required: true
    },
   {
       name: 'connectivity',
       key: 'connectivity',
       value: null,
       type: 'rate',
       max: 10,
       character: <HeartOutlined />,
       displayName: 'Connectivity',
       required: true
   },
   {
       name: 'life_style',
       key: 'life_style',
       value: null,
       type: 'rate',
       max: 10,
       character: <HeartOutlined />,
       displayName: 'Life Style',
       required: true
   },
   {
       name: 'livability',
       key: 'livability',
       value: null,
       type: 'rate',
       max: 10,
       character: <HeartOutlined />,
       displayName: 'Livability',
       required: true,
   },
   {
       name: 'value_for_money',
       key: 'value_for_money',
       value: null,
       type: 'rate',
       max: 10,
       character: <HeartOutlined />,
       displayName: 'Value For Money',
       required: true
   },
   {
       name: 'description',
       key: 'description',
       value: null,
       type: 'rich_text',
       displayName: 'Description'
   }
]

const PropertyBasicInformation = React.memo(({ data, fns, builders, propertyType, ...props }) => {
    return (
        <AccordionStep {...props} displayName="Basic Information">
            <Card>
                <Form {...form.layout}>
                    {data.filter(f => {
                        if (f.propertyType === propertyType || f.propertyType == null) {
                            return true;
                        }
                        return false;
                    }).map(field => {
                        if (field.name === 'builder') {
                            return (
                                <Form.Item {...fns.getItemProps(field)}>
                                    <Select
                                        value={field.value}
                                        onChange={value => fns.setFieldValue(field, value)}
                                    >
                                        {
                                            builders.map(builder => (
                                                <Select.Option
                                                    value={builder._id}
                                                >
                                                    {builder.name}
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            )
                        }

                        return fns.getFormItem(field.name)
                    })}
                </Form>
            </Card>
        </AccordionStep>
    )
});

PropertyBasicInformation.formFields = formFields;

export default PropertyBasicInformation;
