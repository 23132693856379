import { post, get, _delete } from '../../../assets/api';

function saveBlog(data){
    const payload = {
        ...data,
        seo: 'null'
    };

    console.log(data);

    if (data._id) {
        return new Promise((resolve, reject) => {
            post(`/api/v1/cms/blog/${data._id}`, payload, { auth: true })
                .then(async res => {
                    if (res.status === 200 || res.status === 201) {
                        resolve({
                            status: 200,
                            _id: data._id,
                            slug: data.slug
                        });
                    } else {
                        throw new Error('status not handled')
                    }
                }).catch(error => {
                    console.error(error);
                    resolve({
                        status: 500,
                        msg: 'Please try again!'
                    });
                });
        })
    }

    return new Promise((resolve, reject) => {
        post('/api/v1/cms/blog/create', payload, { auth: true })
            .then(async (res) => {
                console.log(res);
                if(res.status === 201) {
                    const data = await res.json();
                    resolve({
                        status: 200,
                        _id: data._id,
                        slug: data.slug
                    })
                } else if (res.status === 409) {
                    const data = await res.json();
                    resolve({
                        status: 409,
                        msg: data.msg
                    });
                } else if (res.status === 500) {
                    const data = await res.json();
                    if (data.error) {
                        resolve({
                            status: 500,
                            msg: data.error
                        });
                    } else {
                        throw new Error('status not handled');
                    }
                } else {
                    throw new Error(`status not handled ${res.status}`);
                }
            }).catch(error => {
                console.error(error);
                resolve({
                    status: 500,
                    msg: 'Please try again!'
                });
            });
    })

}

function getBlogs() {
    return new Promise((resolve, reject) => {
        get('/api/v1/cms/blog', undefined, { auth: true })
         .then(async (res) => {
            if (res.status === 200) {
                const data = await res.json();
                resolve({
                    data: data || [],
                    status: 200
                });
            } else {
                throw new Error('status not handled');
            }
         }).catch(error => {
             console.error('error');
             reject(error);
         })
    })
}

function deleteBlog(payload){
    return new Promise((resolve, reject) => {
        _delete(`/api/v1/cms/blog/delete`, payload, { auth: true })
            .then(res => {
                if (res.status === 200) {
                    resolve({
                        status: 200
                    });
                } else {
                    throw new Error('status not handled');
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
    })
}

function getBlog(slug){
    return new Promise((resolve, reject) => {
        get(`/api/v1/cms/blog/${slug}`, undefined, { auth: true })
            .then(async (res) => {
                if (res.status === 200) {
                    const data = await res.json();
                    resolve({
                        status: 200,
                        data: data || {}
                    });
                } else {
                    throw new Error('status not handled');
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
    })
}

export {
    getBlogs,
    saveBlog,
    getBlog,
    deleteBlog
}
