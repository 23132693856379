import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Modal } from 'antd';
import { 
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';

import { getLocalities, deleteLocality } from '../../api';
import EditLocality from './components/EditLocality';

const ActionSetup = (props) => {

    const [open, setOpen] = useState(false);

    function onClose() {
        setOpen(false);
    }

    function handleOpen() {
        setOpen(true);
    }

    function showPromiseConfirm() {
        Modal.confirm({
          title: 'Do you want to delete locality?',
          icon: <ExclamationCircleOutlined />,
          content: `This will delete the locality ${props.locality}. This will unset the locality where ever used in projects and properties. If you are doing this to correct a error, please use the edit option.`,
          onOk() {
            return deleteLocality([props._id]);
          },
          onCancel() {},
        });
      }

    return (
        <>
            <Space>
                <Button
                    icon={<EditOutlined />}
                    type="primary" 
                    stlye={{ width: '128px'}}
                    onClick={handleOpen}
                >
                    Edit
                </Button>

                <Button
                    type="ghost"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={showPromiseConfirm}
                >
                    Delete
                </Button>
            </Space>
            {
                open && (
                    <EditLocality
                        open={open}
                        onClose={onClose}
                        title={"Edit Locality"}
                        value={props}
                        localities={props.localities}
                        slug={props.slug}
                        showAlert={true}
                    />
                )
            }
        </>
    );
}

const LocalitiesList = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getLocalities()
            .then((res) => {
                if(res.status === 200) {
                    setData(res.localities);
                }
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            })
    }, []);

    const localityColumn = [
        {
            title: 'Locality Name',
            key: 'locality',
            dataIndex: 'locality'
        },
        {
            title: 'City Name',
            key: 'city',
            dataIndex: 'city',
        },
        {
            title: 'State Name',
            key: 'state',
            dataIndex: 'state'
        },
        {
            title: 'Action',
            key: 'action',
            render: (props) => {
                return (
                    <ActionSetup 
                        { ...props}
                        localities={data}
                        slug={props.slug} 
                    />
                )
            }
        }
    ];

    return (
        <>
            <Table
                columns={localityColumn}
                dataSource={data}
                loading={loading}
            />
            <EditLocality 
                open={props.openEdit}
                onClose={props.closeEdit}
                title="Add New Locality"
                localities={data}
            />
        </>
    );
}

export default LocalitiesList;
