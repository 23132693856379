import axios from 'axios';
import React, { Component } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload';

async function sleep (t) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, t);
    })
}

class MyUploadAdapter {
    constructor( loader ) {
        // CKEditor 5's FileLoader instance.
        this.loader = loader;

        // URL where to send files.
        this.url = 'https://backend.investoxpert.com/api/v1/image-upload/';
    }

    // Starts the upload process.
    upload() {
        return new Promise(async (resolve, reject) => {

            const formData = new FormData();

            formData.append('image', await this.loader.file);

            axios
                .post(this.url, formData, {
                    headers: {
                        Authorization: `${localStorage.getItem('token')}`,
                    },
                    onUploadProgress: ({ total, loaded }) => {
                        this.loader.uploadTotal  = total;
                        this.loader.uploaded = loaded;
                    },
                })
                    .then(async ({ data: response }) => {
                        if (response.result && response.result[0] && response.result[0].location) {
                           await sleep(3000); 
                           resolve({
                               default: response.result[0].location
                           });
                        } else {
                            reject(new Error('Something went wrong while uploading file!'));
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        reject(err);
                    });
        })
    }

    // Aborts the upload process.
    abort() {
    }
}

class _CkeEditor extends Component {
    render() {
        const { value, onChange } = this.props;

        return (
            <CKEditor
                editor={ ClassicEditor }
                data={value}
                onReady={ editor => {
                    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                        return new MyUploadAdapter( loader );
                    };
                    this.editor = editor;
                }}
                config={{
                    heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                        ]
                    },
                    mediaEmbed: {
                        previewsInData: true
                    }
                }}
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    onChange(data);
                } }
            />
        )
    }
}

export default _CkeEditor;