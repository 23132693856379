import { useState, useEffect } from 'react';
import { getLocalities } from '../api';

function useLocalities() {
    const [cities, setCities] = useState([]);
    const [localities, setLocalities] = useState([]);

    useEffect(() => {
        getLocalities()
                .then(res => {
                    if (res.status === 200) {
                        setCities(res.cities);
                        setLocalities(res.localities);
                    }
                }).catch(error => {
                    console.log(error);
                });
    }, []);

    return [cities, localities];
}

export default useLocalities;
