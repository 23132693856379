import React, { useState, useMemo, useEffect } from 'react';
import {
    Drawer,
    Button,
    Form,
    Input,
    AutoComplete,
    Alert
} from 'antd';

import { updateLocality, createNewLocality } from '../../../api';

const EditLocality = ({ 
    open,
    onClose,
    title = "Add / Edit Locality",
    localities,
    slug,
    showAlert
}) => {
    const [form] = Form.useForm();

    const _cities = useMemo(() => [...new Set(localities.map(l => l.city))], [localities]);
    const _states = useMemo(() => [...new Set(localities.map(l => l.state))], [localities]);

    const [cities, setCities] = useState(_cities);
    const [states, setStates] = useState(_states);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const localityObj = localities.filter(l => l.slug === slug)[0] || {};

        form.setFieldsValue({
            locality: localityObj.locality || '',
            state: localityObj.state || '',
            city: localityObj.city || '',
            geoJSON: JSON.stringify(localityObj.geoJSON || {}),
            country: 'India'
        });
    }, [localities, form, slug]);

    const onCitySearch = (value) => {
        const filteredCities = _cities.filter(city => city.toLowerCase().includes(value.toLowerCase().trim()) || value === '');
        setCities(filteredCities);
    }

    const onStateSearch = (value) => {
        const filteredStates = _states.filter(state => state.toLowerCase().includes(value.toLowerCase().trim()) || value === '');
        setStates(filteredStates);
    }

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            setLoading(true);
            
            const _values = {
                city: values.city.trim(),
                state: values.state.trim(),
                country: values.country,
                locality: values.locality.trim(),
                geoJSON: values.geoJSON.trim() && JSON.parse(values.geoJSON.trim()) 
            }

            if (slug) {
                updateLocality(_values, slug)
                    .then(res => {
                        if (res.status === 200) {
                            onClose();
                        }
                    }).catch(() => {

                    }).finally(() => {
                        setLoading(false);
                    })
            } else {
                createNewLocality(_values)
                    .then((res) => {
                        if (res.status === 200) {
                            onClose();
                        }
                    }).catch(error => {

                    }).finally(() => {
                        setLoading(false);
                    })
            }

        } catch(error) {
            console.error('error');
            setLoading(false);
        }
    }


    return (
        <>
            <Drawer
                title={title}
                placement="right"
                closable={true}
                onClose={onClose}
                visible={open}
                width="500"
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button
                            type="primary"
                            style={{ marginRight: 8 }}
                            onClick={handleSubmit}
                            loading={loading}
                        >
                            Save
                        </Button>
                        <Button
                            onClick={onClose}
                        >
                            Close
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" form={form}>
                    { showAlert && (
                        <Alert 
                            message="Information Note"
                            description="Updating locality will replace the current locality in project and properties with the new locality."
                        />
                    )}
                    <Form.Item
                        label="Locality Name" 
                        style={{ marginTop: 16 }}
                        rules={[
                            { required: true, message: 'Locality name is required' },
                        ]}
                        hasFeedback
                        name="locality"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item 
                        label="City"
                        name="city"
                        hasFeedback
                        rules={[{ 
                            required: true, message: 'City name is required'
                        }, ( { getFieldValue }) => ({
                            validator(rule, value) {
                                const locality = getFieldValue('locality').toLowerCase().trim();
                                if (slug) {
                                    return Promise.resolve();
                                } else {
                                    const isUnique = localities.filter(l => {
                                        if (l.city.toLowerCase() === value.toLowerCase().trim() && 
                                            l.locality.toLowerCase() === locality) {
                                            return true;
                                        }
                                        return false;
                                    }).length === 0;
                                    if (isUnique) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('Locality and City pair is already added, please add a unique city and locality pair')
                                }
                            }
                        })]}
                    >
                        <AutoComplete
                            onSearch={onCitySearch}
                        >
                            { cities.map(city => {
                                return (
                                    <AutoComplete.Option key={city} value={city}>
                                        {city}
                                    </AutoComplete.Option>
                                )
                            })}
                        </AutoComplete>
                    </Form.Item>
                    <Form.Item 
                        label="State"
                        name="state"
                        rules={[{ required: true, message: 'State name is required' }]}
                        hasFeedback
                    >
                        <AutoComplete onSearch={onStateSearch} >
                            {
                                states.map(state => {
                                    return (
                                        <AutoComplete.Option key={state} value={state}>
                                            {state}
                                        </AutoComplete.Option>
                                    )
                                })
                            }
                        </AutoComplete>
                    </Form.Item>
                    <Form.Item
                        label="Boundary Data (GeoJSON)"
                        name="geoJSON"
                        help={<>
                            <p style={{margin: 0}}>
                                generate new boundary
                                path on <a style={{margin: 0}} href="https://geojson.io/#map=5/24.327/78.267" target="_blank" rel="noreferrer">geojson.io</a>
                            </p>
                        </>}
                        style={{ marginTop: 16 }}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Country" name="country" style={{ marginTop: 16 }}>
                        <Input disabled />
                    </Form.Item>
                </Form>
              
            </Drawer>
        </>
    );
}

export default EditLocality;
