import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import {
    job_columns
} from './fixtures';

import { listJobs } from '../../api';

const BlogsList = ({ type }) => {

    const [data, setData] = useState();
    const [loading, setLoading] = useState();

    useEffect(() => {
        _getJobs({ page: 1, limit: 10 });
    }, [type]);

    const _getJobs = (payload) => {
        setLoading(true);
        listJobs({ ...payload, type })
            .then(res => {
                if(res.status === 200) {
                    let parsedData = res.data.map(d => ({ ...d, key: d._id, edit_url: `/jobs/edit`}))
                    setData(parsedData);
                } else {
                    throw new Error('status not handled');
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            })
    }

    return (
        <Table
            columns={job_columns}
            dataSource={data}
            loading={loading}
        />
    )
}

export default BlogsList;
