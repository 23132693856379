import React, { useState, useCallback, useEffect } from 'react';
import {
    Alert,
    Button,
    Card,
    Dropdown,
    Form,
    Menu,
    Spin,
    Space,
} from 'antd';
import { useHistory } from 'react-router-dom';
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';

import { form } from '../../../../assets/config';
import { useCustomForm, useFormProcess, useQuery } from '../../../../hooks';
import { saveBlog, getBlog } from '../../api';

const EditBlog = () => {
    const query = useQuery();
    const history = useHistory();
    const [data, fns] = useCustomForm(formFields);
    const [meta, setMeta] = useState({ publishStatus: false, loading: false, error: false, _id: null });
    const [saveStatus, saveStatusFns] = useFormProcess();
    const [loadStatus, loadStatusFns] = useFormProcess();

    const _getBlog = useCallback(() => {
        let ref = query.get('ref');
        if (ref === 'new') {
            return;
        }
        loadStatusFns.setLoading(true);
        getBlog(ref)
            .then(res => {
                if (res.status === 200) {
                    fns.updateData(res.data)
                    setMeta(d => ({ ...d, publishStatus: res.data.publish, slug: res.data.slug, _id: res.data._id }))
                    loadStatusFns.setLoading(false);
                } else {
                    throw new Error('status not handled');
                }
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                loadStatusFns.setLoading(false);
            })
    }, []);

    useEffect(() => {
        _getBlog();
    }, [_getBlog])

    function _saveBlog() {
        const [values, errors] = fns.serialize();
        if (errors.length > 0) {
            saveStatusFns.setErrorMessage(errors[0].help);
            return;
        }
        saveStatusFns.setLoading(true);

        const payload = {
            ...values,
            _id: meta._id
        }

        saveBlog(payload)
            .then(res => {
                if(res.status === 200) {
                    console.log(res);
                    if (!meta._id || res._id) {
                        saveStatusFns.setErrorMessage('');
                        setMeta(d => ({
                            ...d,
                            _id: res._id
                        }));
                        history.push(`/blogs/edit?ref=${res.slug}`);
                        window.location.reload();
                    }
                } else if (res.status === 409) {
                    if (res.msg) {
                        saveStatusFns.setErrorMessage(res.msg);
                    } else {
                        saveStatusFns.setErrorMessage('Title already exists.');
                    }
                } else if (res.status === 500) {
                    if (res.msg) {
                        saveStatusFns.setErrorMessage(res.msg)
                    } else {
                        throw new Error('Please try again');
                    }
                } else {
                    throw new Error('status not handled');
                }
            }).catch(error => {
                console.error(error);
                saveStatusFns.setErrorMessage('Please try again');
            }).finally(() => {
                saveStatusFns.setLoading(false);
            })
    }

    function togglePublish(publish) {
        const payload = {
            _id: meta._id,
            publish: publish
        };

        setMeta(d => ({ ...d, loading: true }));
        saveBlog(payload)
            .then(res => {
                if(res.status === 200) {
                    setMeta(d => ({ ...d, publishStatus: publish }));
                } else {
                    throw new Error('status not handled');
                }
            }).catch(error => {
                console.error(error);
                setMeta(d => ({ ...d, error: true }));
            }).finally(() => {
                setMeta(d => ({ ...d, loading: false }));
            })
    }

    function handleMenuClick(e) {
        togglePublish(e.key==='true');
    }

    const menu = () => {
        
        
        return (
            <Menu onClick={handleMenuClick}>
                {
                    meta.publishStatus ? (
                        <Menu.Item key={false}>Un-Publish</Menu.Item>
                    ) : (
                        <Menu.Item key={true}>Publish</Menu.Item>
                    )
                }
            </Menu>
        );
    }


    let PublishIcon = () => null;

    if (meta.publishStatus) {
        PublishIcon = CheckCircleOutlined;
    }
    
    if (meta.error) {
        PublishIcon = ExclamationCircleOutlined;
    }

    return (
        <>
            {
                meta.publishStatus && (
                    <Alert 
                        type="info"
                        description={
                            <>
                                <a href={`https://www.investoxpert.com/blog/${meta.slug}`} target="_blank" rel="noopener noreferrer">
                                    Live Link: https://www.investoxpert.com/blog/{meta.slug}
                                </a>
                            </>
                        }
                        message={"Status Live"}
                        showIcon 
                    />
                )
            }
            <Card 
                bodyStyle={{ padding: 16 }}
                style={{ marginBottom: 4, display: 'flex', justifyContent: 'flex-end' }}
            >
                <Space>
                    <Button type="primary" style={{ width: 120 }}
                        onClick={_saveBlog}
                        loading={saveStatus.loading}
                    >
                        Save
                    </Button>
                    {
                        meta._id && (
                            <Dropdown.Button
                                overlay={menu}
                                buttonsRender={([left, right]) => [
                                    left,
                                    React.cloneElement(right, { loading: meta.loading })
                                ]}
                                onClick={() => togglePublish(!meta.publishStatus)}
                            >
                                {
                                    meta.publishStatus ? (
                                        <Space>
                                            <PublishIcon /> Published
                                        </Space>
                                    ) : 'Not Published'
                                }
                            </Dropdown.Button>
                        )
                    }
                </Space>
            </Card>
            {
                saveStatus.errorMsg && (
                    <Alert
                        type="error"
                        message={saveStatus.errorMsg}
                        showIcon 
                    />
                )
            }
            <Spin spinning={loadStatus.loading}>
                <Card>
                    <Form {...form.layout}>
                        {
                            data.map(field => {
                                return fns.getFormItem(field.name);
                            })
                        }
                    </Form>
                </Card>
            </Spin>
        </>
    )
}


const formFields = [
    {
        name: 'title',
        key: 'title',
        displayName: 'Title',
        type: 'text',
        required: true,
        value: ''
    },
    {
        name: 'slug',
        key: 'slug',
        displayName: 'Slug',
        type: 'text',
        required: true,
        value: ''
    },
    {
        name: 'category',
        key: 'category',
        displayName: 'Category',
        type: 'text',
        required: true,
        value: ''
    },
    {
        name: 'keywords',
        key: 'keywords',
        displayName: 'Keywords',
        defaultErrorMessage: 'Keywords Ex: "Real Estate, Luxury Apartments"',
        type: 'text',
        required: true,
        value: ''
    },
    {
        name: 'excerpt',
        key: 'excerpt',
        displayName: 'Description',
        type: 'text_area',
        required: true,
        value: null
    },
    {
        name: 'image',
        key: 'image',
        displayName: 'Featured Image',
        type: 'image_upload',
        required: true,
        aspectRatio: 1.77778,
        limit: 1,
        value: []
    },
    {
        name: 'content',
        key: 'content',
        displayName: 'Content',
        type: 'cke',
        value: null
    },
];

export default EditBlog;