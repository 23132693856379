import React, { useState, useEffect } from 'react';
import {
    Card,
    Steps,
    Button,
    Space,
    Spin,
    Form,
    Radio,
    Alert,
    Menu,
    Dropdown
} from 'antd';
import {
    ExclamationCircleOutlined,
    CheckCircleOutlined,
} from '@ant-design/icons';
import{ useRouteMatch, useHistory } from 'react-router-dom';

import { useQuery, useCustomForm } from '../../../../hooks';
import { hooks as localitiesHooks } from '../../../localities';
import { hooks as builderHooks } from '../../../builders';
import { getProperty, updateProperty } from '../../api';
import {
    PropertyMeta,
    PropertyLocation,
    PropertyBasicInformation,
    PropertySpecification,
    PropertyBanks,
    PropertyAmenities
} from './components';

const EditProject = () => {
    const [current, setCurrent] = useState();
    const query = useQuery();
    const { params } = useRouteMatch();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [submiting, setSubmiting] = useState(false);
    const [_id, setId] = useState(undefined);
    const [cities, localities] = localitiesHooks.useLocalities();
    const builders = builderHooks.useBuilders();
    const [metaData, metaFns] = useCustomForm(PropertyMeta.formFields);
    const [locationData, locationFns] = useCustomForm(PropertyLocation.formFields);
    const [specData, specFns] = useCustomForm(PropertySpecification.formFields);
    const [basicData, basicDataFns] = useCustomForm(PropertyBasicInformation.formFields);
    const [bankData, bankDataFns] = useCustomForm(PropertyBanks.formFields);
    const [amenitiesData, amenitiesDataFns] = PropertyAmenities.useProjectAmenities();
    const [saveErrorMsg, setSaveErrorMsg] = useState('');

    const slugField = (metaData || []).find(f => f.name === 'slug') || {};

    const [publish, setPublish] = useState({
        loading: false,
        status: false,
        error: false
    }) 

    const propertyType = params.property_type;
    const ref = query.get('ref');

    useEffect(() => {

        if (ref === 'new') {
            return;
        }

        setLoading(true);
        getProperty(ref, propertyType)
            .then(res => {
                if (res.status === 200) {
                    let { data = {}} = res;

                    data = {
                        ...data,
                        connectivity: (data.decision_drivers || {}).connectivity,
                        life_style: (data.decision_drivers || {}).life_style,
                        livability: (data.decision_drivers || {}).livability,
                        value_for_money: (data.decision_drivers || {}).value_for_money
                    }

                    const priceCollection = (data.tags || []).find(tag => {
                        return ["Luxury", "Affordable", "Ultra-Luxury"].includes(tag.tag);
                    });

                    let metaData = {
                        ...data,
                        priceCollection: (priceCollection || {}).tag || "No-Collection"
                    }

                    console.log(metaData);

                    setId(data._id);
                    locationFns.updateData(data);
                    metaFns.updateData(metaData);
                    specFns.updateData(data);
                    basicDataFns.updateData(data);
                    bankDataFns.updateData(data);
                    amenitiesDataFns.updateData(data);

                    setPublish(s => ({
                        ...s,
                        status: data.publish
                    }))

                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            })
    }, []);

    const onSubmit = () => {
        let _values = {};
        let _errors = {};

        [
            metaFns,
            locationFns,
            basicDataFns,
            specFns,
            bankDataFns,
            amenitiesDataFns
        ].forEach(fns => {
            _values = {..._values, ...fns.formatData()[0] };
            _errors = {..._errors, ...fns.formatData()[1] };
        })

        const priceCollection = _values.priceCollection;

        let tags = (_values.tags || []).filter(tag => {
            return !["No-Collection", "Affordable", "Luxury", "Ultra-Luxury"].includes(tag.tag);
        });

        if (priceCollection) {
            tags = [...tags, { tag: priceCollection, status: true }];
        }

        _values = {
            ..._values,
            tags,
            decision_drivers: {
                connectivity: _values.connectivity,
                life_style: _values.life_style,
                livability: _values.livability,
                value_for_money: _values.value_for_money
            }
        }

        setSubmiting(true);
        setSaveErrorMsg('');
        updateProperty(_id, { ..._values }, propertyType)
            .then(res => {
                if(res.status === 200) {
                    setId(res._id);
                    history.push(`/properties/${propertyType}/edit?ref=${res.slug}`);
                } else if (res.status === 409 || res.status === 500) {
                    setSaveErrorMsg(res.msg);
                } else {
                    throw new Error('Status not handled');
                }
            }).catch(error => {
                console.log(error);
                setSaveErrorMsg('Error! Please Try Again.');
            }).finally(() => {
                setSubmiting(false);
            });
    }

    const onPublish = (status) => {
        setPublish(s => ({
            ...s,
            loading: true
        }));

        if(!_id) {
            return;
        }

        updateProperty(_id, { publish: status }, propertyType)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    setPublish(s => ({
                        ...s,
                        loading: false,
                        status 
                    }))
                } else {
                    throw new Error('');
                }
            }).catch(e => {
                setPublish(s => ({
                    ...s,
                    loading: false,
                    error: true
                }));
            })
    }

    const onPropertyTypeChange = (e) => {
        const value = e.target.value;
        
        if (value === 'resale') {
            history.push(`/properties/resale/edit?ref=${slugField.value || 'new'}`);
        }

        if (value === 'rental') {
            history.push(`/properties/rental/edit?ref=${slugField.value || 'new'}`);
        }
    }

    let PublishIcon = () => null;

    if (publish.status) {
        PublishIcon = CheckCircleOutlined;
    }
    
    if (publish.error) {
        PublishIcon = ExclamationCircleOutlined;
    }

    function handleMenuClick(e) {
        onPublish(e.key==='true');
    }

    const menu = () => {
        return (
            <Menu onClick={handleMenuClick}>
                {
                    publish.status ? (
                        <Menu.Item key={false}>Un-Publish</Menu.Item>
                    ) : (
                        <Menu.Item key={true}>Publish</Menu.Item>
                    )
                }
            </Menu>
        );
    }

    const LivePageLink = (
        <Space>
            Live on link :
            <a
                href={`https://www.investoxpert.com/${propertyType}/${slugField.value}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                https://www.investoxpert.com/{propertyType}/{slugField.value}
            </a>
        </Space>
    );


    return (
        <>
            {
                publish.status && (
                    <Alert 
                        type="info" 
                        description={LivePageLink}
                        message={"Status Live"}
                        showIcon 
                    />
                )
            }
            <Card 
                bodyStyle={{ padding: 16 }}
                style={{ marginBottom: 4 }}
                extra={
                    <Space>
                        <Button type="primary" style={{ width: 120 }}
                            onClick={onSubmit}
                            loading={submiting}
                        >
                            Save
                        </Button>
                        {
                            _id && (
                                <Dropdown.Button 
                                    overlay={menu}
                                    buttonsRender={([left, right]) => [
                                        left, 
                                        React.cloneElement(right, { loading: publish.loading })
                                    ]}
                                    onClick={() => onPublish(!publish.status)}
                                >
                                    { 
                                        publish.status ? (
                                            <Space> 
                                                <PublishIcon /> 
                                                Published 
                                            </Space> 
                                        ) : 'Not Published'
                                    }
                                </Dropdown.Button>
                            )
                        }
                    </Space>
                }
            >
                <Form labelCol={{ offset: 1 }}>
                    {saveErrorMsg &&
                        <Form.Item>
                            <Alert
                                message={saveErrorMsg} 
                                type="error" 
                                closable
                                showIcon
                            />
                        </Form.Item>
                    }
                    <Form.Item label="Property Type">
                        <Radio.Group
                            value={propertyType}
                            onChange={onPropertyTypeChange}
                            optionType="button"
                            options={[
                                { label: 'Resale', value: 'resale'},
                                { label: 'Rental', value: 'rental'}
                            ]}
                        />
                    </Form.Item>
                </Form>
            </Card>
            <Spin spinning={loading || submiting}>
                <Card>
                    <Steps
                        direction="vertical"
                        current={current}
                        onChange={setCurrent}
                    >
                        <PropertyMeta 
                            data={metaData}
                            fns={metaFns}
                        />
                        <PropertyLocation 
                            cities={cities}
                            localities={localities}
                            data={locationData}
                            fns={locationFns}
                        />

                        <PropertyAmenities
                            data={amenitiesData}
                            fns={amenitiesDataFns}
                        />

                        <PropertyBasicInformation 
                            data={basicData}
                            fns={basicDataFns}
                            builders={builders}
                            propertyType={propertyType}
                        />

                        <PropertySpecification 
                            data={specData}
                            fns={specFns}
                            propertyType={propertyType}
                        />

                        {
                            propertyType === 'resale' && (
                                <PropertyBanks
                                    data={bankData}
                                    fns={bankDataFns}
                                    propertyType={propertyType}
                                />
                            )
                        }
                    </Steps>
                </Card>
            </Spin>
        </>
    );
}

export default EditProject;
