import React from 'react';
import {
    useHistory
} from 'react-router-dom';
import { Typography, Row, Col, Divider } from 'antd'

import { PageContainer } from '../../components';
import AddAmenities from './components/AddAmenities';
import AmenitiesList from './components/AmenitiesList';

const Amenities = () => {
    const history = useHistory();

    if (!localStorage.getItem('token')) {
        history.push('/login');
    }

    return (
        <PageContainer>
            <div style={{ background: '#fff' }}>
                <Row gutter={[0, 0]}>
                    <Col offset="0" span="22" style={{ marginLeft: 16 }}>
                        <Typography.Title level={1}> Amenities </Typography.Title>
                    </Col>
                </Row>
            </div>
            <Divider style={{ borderTop: '1px solid #eee', margin: 0 }}/>
            <div style={{ margin: '1rem 1rem' }}>
                <AddAmenities />
                <AmenitiesList />
            </div>
        </PageContainer>
    )
};

export default Amenities;
