import {
    Input,
    Form,
    Radio,
    Select,
    Rate,
    Checkbox,
    InputNumber,
    Row,
    Col
} from 'antd';
import React, { useState, createRef, useMemo } from 'react';
import validator from 'validator';
import slugify from 'slugify';
import {
    Upload,
    RichTextInput,
    CkeInput
} from '../components';
import { embedLink } from '../assets/fns';

const STATUS_ERROR = 'error';
const STATUS_SUCCESS = 'success'

const getEmptyTemplate = (item) => {
    if (item.type === 'image_upload') {
        return `Please upload a ${item.displayName} image`;
    }

    if (item.type === 'radio' || item.type === 'checkbox_group') {
        return `Please select ${item.displayName}`;
    }

    if (item.type === 'rate') {
        return `Please rate ${item.displayName}`;
    }

    if (item.type === 'location') {
        return `Please paste iframe link from google maps`;
    }

    return `Please enter ${item.defaultErrorMessage || item.displayName}`
}


function requiredValidator(item, getTemplate) {
    let value = item.value;

    const errRes = [
        false,
        {
            help: getTemplate(item),
            status: STATUS_ERROR
        }
    ]

    if (item.type === 'text' || item.type === 'text_area' || item.type === 'radio' || item.type === 'select') {
        value = value ? value.trim() : value;
        if (value === '' || value == null) {
            return errRes;
        }
    }

    if (item.type === 'rate' || item.type === 'number') {
        if (value == null || value === '') {
            return errRes;
        }
    }

    if (item.type === 'image_upload') {
        if (item.value.length === 0) {
            return errRes;
        }

        if ((item.value[0] || {}).status !== 'done' && !((item.value[0] || {}).status == null)) {
            return errRes;
        }
    }

    if (item.type === 'location') {
        const [lat, long] = item.cord;

        const isCordNull = lat == null || long == null;

        if (isCordNull || !validator.isLatLong(item.cord.join(','))) {
            return errRes
        }
    }

    return [true, {}];
}

const useCustomForm = (form) => {
    const refs = useMemo(() => {
        let _refs = {};
        form.forEach(field => {
            if (field.type === 'rich_text') {
                _refs[field.name] = createRef();
            }
        });
        return _refs;
    }, [form]);

    const [values, setValues] = useState(form);

    let validators = [
        {
            validator: requiredValidator,
            template: getEmptyTemplate
        }
    ];

    const setFieldValues = (valuesObj) => {
        setValues(values => {
            let newValues = values.map(item => {
                if (item.type === 'rich_text') {
                    refs[item.name].current = valuesObj[item.name];
                }

                if (valuesObj[item.name]) {
                    const value = valuesObj[item.name];

                    if (typeof value === 'string') {
                        return {
                            ...item,
                            value
                        };
                    }

                    return {
                        ...item,
                        ...value
                    }

                }

                return item;
            });

            return newValues;
        });
    }

    const getFieldValue = (name) => {
        const item =  values.find(item => item.name === name) || {};

        if (item.type === 'rich_text') {
            return refs[name].current;
        }

        return item.value;
    }

    const setFieldValue = (props, value, extra) => {
        if (props.type === 'rich_text') {
            refs[props.name].current = value;
            return;
        }

        setValues(values => {
            const newState = values.map((formItem) => {
                if (formItem.name === props.name ) {
                    let extras = {};

                    if (formItem.type === 'location') {
                        extras = extra;
                    }

                    return {
                        ...props,
                        ...extras,
                        value
                    }
                }
                return formItem;
            })
            return newState;
        })
    }

    const getInputField = (type, props) => {
        const {
            value
        } = props;

        switch (type) {
            case 'text':
                return <Input 
                            value={value}
                            onChange={(e) => {
                                const value = e.target.value;
                                setFieldValue(props, value);
                            }}
                        />;

            case 'text_area':
                return <Input.TextArea
                        value={value}
                        onChange={(e) => {
                            const value = e.target.value;
                            setFieldValue(props, value);
                        }}
                    />;

            case 'number':
                return <InputNumber 
                            value={value} type="number"
                            max={props.max || Infinity}
                            min={props.min || -Infinity}
                            onChange={(value) => {
                                setFieldValue(props, value);
                            }}
                        />

            case 'radio':
                return (
                    <Radio.Group
                        value={value}
                        options={props.options}
                        optionType={props.optionType}
                        onChange={(e) => {
                            const value = e.target.value;
                            setFieldValue(props, value);
                        }}
                    />
                );

            case 'image_upload':
                return (
                    <Upload
                        fileList={value}
                        setFileList={(value) => setFieldValue(props, value)}
                        limit={props.limit || 10}
                        aspectRatio={props.aspectRatio}
                    />
                )
            
            case 'select':
                return (
                    <Select
                        value={value}
                        onChange={(value) => setFieldValue(props, value)}
                        allowClear
                    >
                        {
                           props.options
                                .map(option => {
                                    return (
                                        <Select.Option value={option.value}>
                                            {option.displayValue}
                                        </Select.Option>
                                    )
                                })
                        }
                    </Select>
                )

            case 'rate':
                return (
                    <Rate 
                        defaultValue={null}
                        count={props.max || 10}
                        character={props.character}
                        value={value}
                        onChange={(value) => setFieldValue(props, value)}
                    />
                )

            case 'rich_text':
                return (
                    <RichTextInput 
                        value={value}
                        onChange={(value) => {
                            setFieldValue(props, value);
                        }}
                    />
                )

            case 'cke':
                return (
                    <CkeInput
                        value={value}
                        onChange={(value) => {
                            setFieldValue(props, value);
                        }}
                    />
                )

            case 'boolean':
                return (
                    <Checkbox 
                        checked={value}
                        onChange={e => {
                            const value = e.target.checked;
                            setFieldValue(props, value);
                        }}
                    />
                )

            case 'checkbox_group':
                if (props.grid) {
                    return (
                        <Checkbox.Group
                            value={value}
                            onChange={value => {
                                setFieldValue(props, value)
                            }}
                        >
                            <Row>
                                {
                                    props.options.map(option => (
                                        <Col {...props.grid}>
                                            <Checkbox value={option}>{option}</Checkbox>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Checkbox.Group>
                    )
                }
                return (
                    <Checkbox.Group
                        options={props.options}
                        value={value}
                        onChange={value => {
                            setFieldValue(props, value)
                        }}
                    />
                )

            case 'location':
                return (
                    <Input
                      value={props.value}
                      onChange={(e) => {
                        const value = e.target.value;
                        const cord = embedLink(value);
                        setFieldValue(props, value, { cord });
                      }}
                    />
                )

            default:
                return null;
        }
    }

    const getValidatorProps = (item) => {
        if (!item.required) {
            return {
                validateStatus: STATUS_SUCCESS
            };
        }

        let validateStatus = STATUS_SUCCESS
        let help = ''

        for (let i = 0; i < validators.length; i +=1 ) {
            const [isValid, res] = validators[i].validator(item, validators[i].template);
            if (!isValid) {
                validateStatus = res.status;
                help = res.help
                break;
            }
        }

        return {
            hasFeedback: true,
            validateStatus,
            help
        }
    }

    const getExtraProps = function(item) {
        let _props = {};

        if (item.type === 'location') {
            _props.extra = 'location coordinates:' + item.cord.join(',')
        }

        return _props;
    }

    const getItemProps = function(item) {
        const validatorProps = getValidatorProps(item);
        const itemProps = getExtraProps(item);

        return {
            ...validatorProps,
            ...itemProps,
            label: item.displayName,
            key: item.key,
            required: item.required
        };
    }

    const getFormItem = (name) => {
        const item = (values.find(item => item.name === name) || {});
        const itemProps = getItemProps(item);

        return (
            <Form.Item
                {...itemProps}
            >
                {getInputField(item.type, item)}
            </Form.Item>
        );
    }

    const formatData = () => {
        let _values = {};
        let errors = values.map(getValidatorProps).filter(item => item.validateStatus !== STATUS_SUCCESS);

        values.forEach(item => {
            if (item.type === 'location') {
                return _values[item.name] = {
                    coordinates: item.cord
                }
            }
            
            if (item.type === 'rich_text') {
                return _values[item.name] = refs[item.name].current;
            }

            if (item.name === 'slug') {
                return _values[item.name] = slugify(item.value.toLowerCase().trim());
            }

            if (item.type === 'image_upload' && item.limit === 1) {
                return _values[item.name] = {
                    location: ((item.value[0] || {}).response || {}).location,
                    image_key: ((item.value[0] || {}).response || {}).image_key,
                }
            }

            if (item.type === 'image_upload') {
                return _values[item.name] = (item.value || []).map((image = {}) => {
                    return {
                        location: (image.response || {}).location,
                        image_key: (image.response || {}).image_key,
                    }
                })
            }

            if (item.type === 'text' || item.type === 'text_area') {
                return _values[item.name] = (item.value || '').trim()
            }

            if (item.serializer && typeof item.serializer === 'function') {
                return _values[item.name] = item.serializer(item);
            }

            return _values[item.name] = item.value;
        })

        return [_values, errors];
    }

    const updateData = (data) => {
        let _values = [];

        form.forEach(item => {
            let value = data[item.name];
            let _item = { };

            if (item.type === 'location') {
                let cord = ((value || {}).coordinates || []);
                value = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1903.1863253095796!2d${cord[0] || ''}!3d${cord[1] || ''}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb915cf23132cf%3A0xa1d3cfe0aab7fc94!2sMontaigne%20Smart%20Business%20Solutions!5e0!3m2!1sen!2sin!4v1602111618782!5m2!1sen!2sin" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`;
                _item = {
                    ...item,
                    value,
                    cord
                };
            } else if (item.type === 'image_upload') {
               if (item.limit === 1) {
                    _item = {
                       ...item,
                       value: [{
                           uid: (value || {}).image_key,
                           url: (value || {}).location,
                           response: {
                               image_key: (value || {}).image_key,
                               location: (value || {}).location
                           }
                       }].filter(i => i.uid != null)
                   };
               } else {
                    _item = {
                       ...item,
                       value: (value || []).map((image = {}) => {
                           return {
                             uid: image.image_key,
                             url: image.location,
                             resposne: {
                                 image_key: image.image_key,
                                 location: image.location
                             }
                           };
                       }).filter(i => i.uid != null)
                   }
               }
            } else if (item.type === 'rich_text') {
                _item = {
                    ...item,
                    value: value
                }

                refs[item.name].current = value;
            } else if (item.deserializer && typeof item.deserializer === 'function') {
                _item = item.deserializer({ ...item, value });
            } else {
                _item = {
                    ...item,
                    value
                }
            }

            _values = [..._values, _item];

        });
        setValues(_values);
    }

    return [
        values,
        {
            getFieldValue,
            setFieldValue,
            setFieldValues,
            getFormItem,
            getItemProps,
            formatData,
            serialize: formatData,
            updateData
        }
    ]
}

export default useCustomForm;
