import React from 'react';
import {
    Card,
    Form,
    Input,
    Button,
    Alert
} from 'antd'

import {
    useFormProcess
} from '../../../hooks';
import { form as formConfig } from '../../../assets/config';
import { createOrUpdateAmenity } from '../api';

const AddAmenities = () => {
    const [form] = Form.useForm();
    const [formProcess, formProcessFns] = useFormProcess();

    const onSubmit = async () => {
        try {
            const values = await form.validateFields();
            
            const _values = {
                category: values.category.trim(),
                name: values.amenity.trim()
            }
            
            formProcessFns.setLoading(true);
            createOrUpdateAmenity(_values)
                .then((res => {
                    if (res.status === 200) {
                        formProcessFns.setErrorMessage('');
                        window.location.reload();
                    } else if (res.status === 400 || res.status === 500) {
                        formProcessFns.setErrorMessage(res.error);
                    } else {
                      throw new Error('Status not handled');
                    }
                })).catch(( ) => {
                    formProcessFns.setErrorMessage('Error! Please Try Again!');
                }).finally(() => {
                    formProcessFns.setLoading(false);
                });
        } catch(error) {
            console.log(error);
        }
    }


    return (
        <Card>
            <Form {...formConfig.layout} form={form}>
                <Form.Item 
                    label="Category"
                    hasFeedback
                    required
                    name="category"
                    rules={[
                        { required: true, message: 'Please enter category' }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item 
                    label="Amenity"
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please enter amenity' }
                    ]}
                    name="amenity"
                >
                    <Input />
                </Form.Item>
                {
                    formProcess.errorMsg && (
                        <Form.Item {...formConfig.tailLayout}>
                            <Alert 
                                message="Error!"
                                description={formProcess.errorMsg}
                                type="error"
                            />
                        </Form.Item>
                    )
                }
                <Form.Item {...formConfig.tailLayout}>
                    <Button 
                        type="primary" 
                        onClick={onSubmit}
                        loading={formProcess.loading}
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default AddAmenities;
