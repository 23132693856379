import React, { useState } from "react";
import { Card, Form, Input, Button, Alert, Divider } from "antd";

import { AccordionStep } from "../../../../../components";
import { useFormProcess } from "../../../../../hooks";
import { form } from "../../../../../assets/config";

const ProjectSchema = ({
   loading,
   value: data,
   fns,
   postProject,
   ...props
}) => {

    const [formProcess, formProcessFns] = useFormProcess();

    const onSubmit = () => {
        postProject(formProcessFns);
    }

   return (
      <AccordionStep {...props} displayName="FAQs">
         <Card loading={loading}>
            <Form {...form.layout}>
                {data.map((question, index) => (
                    <React.Fragment key={index}>
                        <Form.Item label={`Question ${index + 1}`}>
                            <Input.TextArea value={question.question} onChange={(e) => {
                                const value = e.target.value;
                                fns.editQuestion({ ...question, question: value }, index);
                            }} />
                        </Form.Item>
                        <Form.Item label={`Answer ${index + 1}`}>
                            <Input.TextArea value={question.answer} onChange={(e) => {
                                const value = e.target.value;
                                fns.editQuestion({ ...question, answer: value }, index);
                            }} />
                        </Form.Item>
                        <Divider dashed/>
                    </React.Fragment>
                ))}
                <Form.Item {...form.tailLayout}>
                    <Button 
                        type="primary"
                        htmlType="submit"
                        onClick={fns.addNewQuestion}
                    >
                        Add New
                    </Button>
                    <Button
                        style={{ marginLeft: "16px" }}
                        type="primary"
                        htmlType="submit"
                        loading={formProcess.loading}
                        onClick={onSubmit}
                    >
                        Save
                    </Button>
                </Form.Item>
                {formProcess.errorMsg && (
                    <Form.Item {...form.tailLayout}>
                        <Alert message={formProcess.errorMsg} type="error" />
                    </Form.Item>
                )}
            </Form>
         </Card>
      </AccordionStep>
   );
}

function useProjectSchema() {
   const [value, setValue] = useState([]);

   function updateDataFromAPI(data) {
       console.log(data);
       if (data.faq) {
           if (data.faq.length === 0) {
                setValue([{ question: "", answer: "" }])
           } else {
               setValue(data.faq);
           }
       }
   }

   function addNewQuestion() {
       setValue(value => [...value, [{ question: "", answer: "" }]]);
   }

   function editQuestion(newValue, cIndex) {
       setValue(value => {
           return value.map((question, index) => {
               if (cIndex === index) {
                return newValue;
               }
               return question;
           })
       })
   }

   function formatData() {
    return { faq: value.filter(question => question.question !== "" && question.answer !== "" ) };
   }

   return [
       value,
       {
           updateDataFromAPI,
           addNewQuestion,
           formatData,
           editQuestion
       }
   ]
}

ProjectSchema.useProjectSchema = useProjectSchema;

export default ProjectSchema;
