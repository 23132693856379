import { get, post, _delete } from '../../../assets/api';

const getProjects = (payload) => {
    return new Promise((resolve, reject) => {
        get('/api/v1/cms/projects', payload, { auth: true })
            .then(async res => {
                if (res.status === 200 || res.status === 304) {
                    const data = await res.json();

                    resolve({
                        status: 200,
                        data: data.results,
                        count: data.count
                    });

                } else if (res.status === 401) {
                    localStorage.clear();
                    window.location.reload();
                } else {
                    throw new Error('Status not handled');
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            })
    })
}

const getProject = (slug) => {
    return new Promise((resolve, reject) => {
        get(`/api/v1/cms/projects/${slug}`, undefined, { auth: true })
            .then(async res => {
                if (res.status === 200) {
                    const data = await res.json();

                    resolve({
                        status: 200,
                        data
                    })
                } else {
                    throw new Error('Status not handled');
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

const updateProject = (data, id) => {

    let endpoint = '/api/v1/cms/projects';

    if (id) {
        endpoint = endpoint + '/' + id;
    }

    return new Promise((resolve, reject) => {
        post(endpoint, data, { auth: true })
            .then(async res => {
                if (res.status === 200 || res.status === 201) {
                    if (!id) {
                        const data = await res.json();
                        resolve({
                            status: 200,
                            _id: data.data._id
                        })
                        return;
                    }
                    resolve({ status: 200, _id: id });
                    return;
                }
                if (res.status === 409) {
                    const data = await res.json();
                    resolve({ status: 409, error: data.msg || 'Error! Please Try Again'});
                    return;
                }

                if (res.status === 500) {
                    const data = await res.json();
                    resolve({ status: 500, error: data.msg || data.error || 'Error! Please Try Again'});
                    return;
                }

                throw new Error('Status not handlde');
            }).catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

const removeProjects = (ids) => {
    return new Promise((resolve, reject) => {
        _delete('/api/v1/cms/projects', ids, { auth: true })
            .then(async res => {
                if (res.status === 200) {
                    resolve({ 200: true });
                    window.location.reload();
                } else {
                    throw new Error('API Error');
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            })
    })
}

export {
    getProjects,
    getProject,
    updateProject,
    removeProjects
}
