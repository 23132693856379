
export const projectStatus = [
    {name: 'new launch', mSlug: 'new-launch-' },
    {name: 'mid stage', mSlug: 'mid-stage-' },
    {name: 'near possession', mSlug: 'near-possession-' },
    {name: 'ready to move', mSlug: 'ready-to-move-' },
]

export const listingType = [
    {name: 'featured', mSlug: 'featured-' },
    {name: 'trending', mSlug: 'trending-' },
    {name: 'exclusive', mSlug: 'exclusive-' },
    {name: 'luxury', mSlug: 'luxury-' },
    {name: 'affordable', mSlug: 'affordable-' },
    {name: 'ready-to-move-in', mSlug: 'ready-to-move-in-' },
    {name: 'owners', mSlug: 'owners-' }
]

export const residencyType = [
    { name: 'commercial', mSlug: 'commercial-' }
]

export const knownFilters = [
    { key: 'bhk', type: 'array' },
    { key: 'property_type', type: 'array' },
    { key: 'status', type: 'array' },
    { key: 'listing_type', type: 'array' },
    { key: 'furnishing', type: 'array' },
    { key: 'lease_type', type: 'array' },
    { key: 'budget', type: 'objectToRange' },
    { key: 'area', type: 'objectToRange' },
];

function objectArrayZipper(arr, args) {
    /**
     * add properties to every item in a array.
     */
    return  arr.map(item => ({ ...item, ...args }));
}

function getListingUrl(locality, projectType = 'project') {
    let urls = [];
    let mainURLSlug = { 
        mSlug: `property-in-${locality}`,
        filter: { scope: 'project', type: undefined, residency_type: 'residential' } 
    };

    if (projectType === 'resale') {
        mainURLSlug = { 
            mSlug: `resale-property-in-${locality}`, 
            filter: { scope: 'property', type: 'resale', residency_type: 'residential' } 
        };
    }

    if (projectType === 'rental') {
        mainURLSlug = { 
            mSlug: `rental-property-in-${locality}`,
            filter: { scope: 'property', type: 'rental', residency_type: 'residential' }
        };
    }

    let baseUrls = [];

    if (projectType === 'project') {
        baseUrls = [
            { mSlug: '', filter: {} },
            ...objectArrayZipper(projectStatus, { key: 'status', queryFormatType: 'array' }),
            ...objectArrayZipper(listingType, { key: 'listing_type', queryFormatType: 'array' })
        ];
    } else {
        baseUrls = [
            { mSlug: '', filter: {} },
            ...objectArrayZipper(listingType, { key: 'listing_type', queryFormatType: 'array' })
        ];
    }

    urls = [
        { mSlug: mainURLSlug.mSlug, filter: mainURLSlug.filter },
        { mSlug: `commercial-${mainURLSlug.mSlug}`, filter: { ...mainURLSlug.filter, residency_type: 'commercial' }}
    ].map(base => {
        return [...baseUrls].map(url => {
            let finalURL = '/' + base.mSlug;
            let finalFilter = base.filter;
            if (url.queryFormatType === 'array') {
                finalURL = '/' + url.mSlug + base.mSlug;
                finalFilter = {
                    ...finalFilter,
                    ...url.filter,
                    [url.key]: [url.name]
                }
            } if (url.queryFormatType === 'string') {
                finalURL = '/' + url.mSlug + base.mSlug;
                finalFilter = {
                    ...finalFilter,
                    ...url.filter,
                    [url.key]: url.name
                }
            }
    
            return {
                url: finalURL,
                filter: finalFilter
            }
        });
    }).flat();

    return urls;
}

export function getURLsForLocality(locality) {
    let urlConfig = ['project', 'rental', 'resale'].map((projectType) => getListingUrl(locality, projectType)).flat();
    return urlConfig;
}