import React, { useEffect, useState, useCallback } from 'react';
import { Table } from 'antd';
import {
    property_column
} from './fixtures';

import { getProperties } from '../../api';

const PropertyList = ({ type }) => {

    const [data, setData] = useState();
    const [size, setSize] = useState();
    const [currentPage, setCurrentPage] = useState();
    const [pageSize, setPageSize] = useState();
    const [loading, setLoading] = useState();

    const _getProperties = useCallback((payload) => {
        setLoading(true);
        getProperties({ ...payload, type })
            .then(res => {
                if(res.status === 200) {
                    const data = res.data;
                    const parsedData = data.map(({ data: d }) => ({
                        property_name: d.title || '',
                        slug: d.slug || '',
                        _id: d._id,
                        property_type: d.property_type,
                        price: d.monthly_rent || d.price,
                        locality: (d.locality || {}).locality,
                        city: (d.locality || {}).city,
                        construction_status: '',
                        edit_url: `/properties/${type}/edit`
                    }));
                    setData(parsedData);
                    setSize(res.count);
                    setPageSize(payload.pageSize);
                    setCurrentPage(payload.page);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        _getProperties({ page: 1, limit: 10 });
    }, [type, _getProperties]);


    function onTableChange(pagination) {
        _getProperties({ page: pagination.current, limit: pagination.pageSize });
    }

    return (
        <Table
            columns={property_column}
            dataSource={data}
            loading={loading}
            onChange={onTableChange}
            pagination={{ current: currentPage, pageSize, total: size }}
        />
    )
}

export default PropertyList;
