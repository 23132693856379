import { 
    FolderAddOutlined
} from '@ant-design/icons';
import React, { useState } from 'react';
import {
    useRouteMatch,
    useHistory
} from 'react-router-dom';
import {
    Typography,
    Row,
    Button,
    Space,
    Col,
    Divider
} from 'antd'

import { PageContainer } from '../../components';

import { BuildersList } from './frames';

const Localities = () => {
    const history = useHistory();
    const [openEdit, setOpenEdit] = useState();

    if (!localStorage.getItem('token')) {
        history.push('/login')
    }

    return (
        <PageContainer>
            <div style={{ background: '#fff' }}>
                <Row gutter={[0, 0]}>
                    <Col offset="0" span="22" style={{ marginLeft: 16 }}>
                        <Typography.Title level={1}> Builders </Typography.Title>
                    </Col>
                </Row>
                <Space
                    size="small"
                    style={{
                        margin: '0 1.2rem 0 1.2rem',
                        height: 64,
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                    align="center"
                >
                    <Space>
                        <Button
                            type="primary"
                            icon={<FolderAddOutlined />}
                            onClick={() => { setOpenEdit(true); }}
                        >
                            Add Builder
                        </Button>
                    </Space>
                </Space>
            </div>
            <Divider style={{ borderTop: '1px solid #eee', margin: 0 }}/>
            <div style={{ margin: '1rem 1rem' }}>
                <BuildersList
                    openEdit={openEdit}
                    closeEdit={() => { setOpenEdit(false); }}
                />
            </div>
        </PageContainer>
    )
};

export default Localities;
