import { useState, useEffect } from 'react';

import { getBuilders } from '../api';

const useBuilders = () => {
    const [builders, setBuilders] = useState([]);
    const [errorCount, setErrorCount] = useState(0);

    useEffect(() => {
        if (errorCount > 3) {
            return;
        }

        getBuilders()
         .then(res => {
             if (res.status === 200) {
                setBuilders(res.data);
             } else {
                 throw new Error('Status not handled');
             }
         }).catch((error) => {
             setErrorCount(c => c + 1);
         })
    }, [errorCount]);

    return builders;
}

export default useBuilders;