import escapeHtml from 'escape-html'
import { Text } from 'slate'

const serialize = node => {
  if (Text.isText(node)) {
    if (node.bold) {
        return `<strong>${escapeHtml(node.text)}</strong>`
    }
    if (node.italic) {
        return `<i>${escapeHtml(node.text)}</i>`
    }
    if (node.underline) {
        return `<u>${escapeHtml(node.text)}</u>`
    }
    return escapeHtml(node.text);
  }

  const children = (node.children || node).map(n => serialize(n)).join('');

  switch (node.type) {
    case 'block-quote':
        return `<blockquote><p>${children}</p></blockquote>`
    case 'paragraph':
        return `<p>${children}</p>`;
    case 'link':
        return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    case 'bulleted-list':
        return `<ul>${children}</ul>`;
    case 'numbered-list':
        return `<ol>${children}</ol>`;
    case 'list-item':
        return `<li>${children}</li>`;
    case 'heading-one':
        return `<h1>${children}</h1>`;
    case 'heading-two':
        return `<h2>${children}</h2>`;
    case 'heading-three':
        return `<h3>${children}</h3>`;
    default:
        return children
  }
};

export default serialize;
