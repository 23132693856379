import React from 'react';
import {
    useRouteMatch,
    useHistory
} from 'react-router-dom';
import { Typography, Row, Col, Divider, Space, Button } from 'antd';
import { CloudDownloadOutlined  } from '@ant-design/icons';

import { PageContainer } from '../../components';
import LeadsList from './frames/LeadsList';

const LeadManagement = () => {
    const { path } = useRouteMatch();
    const history = useHistory();

    if (!localStorage.getItem('token')) {
        history.push('/login');
    }

    return (
        <PageContainer>
            <div style={{ background: '#fff' }}>
                <Row gutter={[0, 0]}>
                    <Col offset="0" span="22" style={{ marginLeft: 16 }}>
                        <Typography.Title level={1}> Leads </Typography.Title>
                    </Col>
                </Row>
                <Space
                    size="small"
                    style={{
                        margin: '0 1.2rem 0 1.2rem',
                        height: 64,
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                    align="center"
                >
                    <Button
                        type="primary"
                        icon={<CloudDownloadOutlined />}
                        onClick={() => {
                            fetch("https://backend.investoxpert.com/api/v1/contact-us/excel", {
                                method: 'GET',
                                headers: new Headers({
                                    "Authorization": localStorage.getItem('token')
                                })
                            })
                            .then(response => response.blob())
                            .then(blob => {
                                var url = window.URL.createObjectURL(blob);
                                var a = document.createElement('a');
                                a.href = url;
                                a.download = "investoxpert-leads.xlsx";
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            });
                        }}
                    >
                        Download Leads
                    </Button>
                </Space>
            </div>
            <Divider style={{ borderTop: '1px solid #eee', margin: 0 }}/>
            <LeadsList />
        </PageContainer>
    )
};

export default LeadManagement;
