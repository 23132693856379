import {
    FolderAddOutlined,
    AppstoreAddOutlined
} from '@ant-design/icons';
import React, { useState } from 'react';
import {
    useRouteMatch,
    useHistory,
    Route,
    Switch
} from 'react-router-dom';
import {
    Typography,
    Row,
    Button,
    Space,
    Col,
    Divider,
} from 'antd'

import { PageContainer } from '../../components';

import {
    LocalitiesList,
    Meta
} from './frames';

const Localities = () => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [openEdit, setOpenEdit] = useState();

    if (!localStorage.getItem('token')) {
        history.push('/login')
    }

    return (
        <PageContainer>
            <div style={{ background: '#fff' }}>
                <Row gutter={[0, 0]}>
                    <Col offset="0" span="22" style={{ marginLeft: 16 }}>
                        <Typography.Title level={1}> Cities And Localities </Typography.Title>
                    </Col>
                </Row>
               
               
                <Space
                    size="small"
                    style={{
                        margin: '0 1.2rem 0 1.2rem',
                        height: 64,
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                    align="center"
                >
                    <Space>
                        <Button 
                            onClick={() => {
                                history.push('/cities-and-localities');
                            }}
                        >
                            List Localities
                        </Button>
                        <Button
                                icon={<FolderAddOutlined />}
                                onClick={() => { history.push('/cities-and-localities/meta') }}
                            >
                                Meta
                        </Button>
                    </Space>
                    <Space>
                        <Button
                            type="primary"
                            icon={<FolderAddOutlined />}
                            onClick={() => { setOpenEdit(true); }}
                        >
                            Add Locality
                        </Button>
                    </Space>
                </Space>

            </div>
            <Divider style={{ borderTop: '1px solid #eee', margin: 0 }}/>
            <Switch>
                <Route path={`${path}/meta`}>
                    <Meta />
                </Route>
                <Route>
                    <div style={{ margin: '1rem 1rem' }}>
                        <LocalitiesList 
                            openEdit={openEdit}
                            closeEdit={() => { setOpenEdit(false); }}
                        />
                    </div>
                </Route>
            </Switch>
        </PageContainer>
    )
};

export default Localities;
