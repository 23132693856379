import React, { useCallback, useMemo, useState, useEffect } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, useSlate, Slate } from 'slate-react';
import { Editor, Transforms, createEditor, Range } from 'slate';
import { withHistory } from 'slate-history';
import styled from 'styled-components';
import validator from 'validator';
import classnames from 'classnames';
import { Divider } from 'antd';

import {
    serialize,
    deserialize
} from './assets';

const Toolbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #eee;

    margin-top: 1rem;
    padding: 0.4rem 1rem;
`;

const InputContainer = styled.div`
    background: #fdfdfd;

    * {
        margin: 0;
    }

    p {
        line-height: 24px;
    }

    h1 {
        font-size: 24px;
        margin-bottom: 0.8rem;
    }

    h2 {
       margin-bottom: 0.8rem;
    }
    
    h3 {
        margin-bottom: 0.3rem;
    }
`;

const Button = styled.div`
    height: 100%;
    cursor: pointer;
    span {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 32px;
        height: 32px;
        opacity: ${(props) => props.active ? 0.8 : 0.4}
    }
`;

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline'
}

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const safeText = (value => {
    if (value && value[0] && !value[0].children && value[0].text) {
        return [{
            type: 'paragraph',
            children: [{
                text: value[0].text
            }]
        }]
    }
    return value;
})

const RichTextInput = (props) => {
    const document = new DOMParser().parseFromString(props.value || '<body><p> </p></body>', 'text/html');
    const [value, setValue] = useState(safeText(deserialize(document.body)));
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const editor = useMemo(() => withHtml(withLinks(withHistory(withReact(createEditor())))), [])


    useEffect(() => {
        if (props.onChange) {
            props.onChange(serialize(value));
        }
    }, [value, props]);

    useEffect(() => {
        const document = new DOMParser().parseFromString(props.value || '<body><p> </p></body>', 'text/html');
        setValue(safeText(deserialize(document.body)));
    }, [props.value])

    return (
        <InputContainer>
            <Slate editor={editor} value={value} onChange={value => { 
                setValue(value);
            }}>
                <Toolbar>
                    <MarkButton format="bold" icon="format_bold" />
                    <MarkButton format="italic" icon="format_italic" />
                    <MarkButton format="underline" icon="format_underlined" />
                    <Divider type="vertical" style={{ marginRight: '1rem '}}/>
                    {/* <BlockButton format="heading-one" icon="looks_one" /> */}
                    <BlockButton format="heading-two" icon="looks_two" />
                    <BlockButton format="heading-three" icon="looks_3" />
                    <BlockButton format="block-quote" icon="format_quote" />
                    <BlockButton format="numbered-list" icon="format_list_numbered" />
                    <BlockButton format="bulleted-list" icon="format_list_bulleted" />
                    <Divider type="vertical" style={{ marginRight: '1rem '}} />
                    <LinkButton />
                </Toolbar>
                <Editable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder="Enter some rich text…"
                    spellCheck
                    autoFocus
                    style={{
                        margin: '1rem 1rem'
                    }}
                    onKeyDown={event => {
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event)) {
                                event.preventDefault()
                                const mark = HOTKEYS[hotkey]
                                toggleMark(editor, mark)
                            }
                        }
                        
                        if (event.key === 'Enter') {
                            if(isBlockActive(editor, 'heading-two')) {
                                setTimeout(() => {
                                    toggleBlock(editor, 'heading-two');
                                }, 0);
                            }
                            if(isBlockActive(editor, 'heading-one')) {
                                setTimeout(() => {
                                    toggleBlock(editor, 'heading-one');
                                }, 0);
                            }
                            if(isBlockActive(editor, 'heading-three')) {
                                setTimeout(() => {
                                    toggleBlock(editor, 'heading-three');
                                }, 0);
                            }
                        }
                    }}
                />
            </Slate>
        </InputContainer>
    )
};

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format)
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n => LIST_TYPES.includes(n.type),
        split: true,
    })

    Transforms.setNodes(editor, {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    })

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: n => n.type === format,
    })

    return !!match
}

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element }) => {
    switch (element.type) {
        case 'link':
            return (
                <a {...attributes} href={element.url}>
                    {children}
                </a>
            )
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>
        case 'heading-three':
            return <h3 {...attributes}>{children}</h3>
        case 'list-item':
            return <li {...attributes}>{children}</li>
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>
        default:
            return <p {...attributes}>{children}</p>
    }
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}

const insertLink = (editor, url) => {
    if (editor.selection) {
        wrapLink(editor, url)
    }
}

const isLinkActive = editor => {
    const [link] = Editor.nodes(editor, { match: n => n.type === 'link' })
    return !!link
}

const unwrapLink = editor => {
    Transforms.unwrapNodes(editor, { match: n => n.type === 'link' })
}

const wrapLink = (editor, url) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor)
    }

    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const link = {
        type: 'link',
        url,
        children: isCollapsed ? [{ text: url }] : [],
    }

    if (isCollapsed) {
        Transforms.insertNodes(editor, link)
    } else {
        Transforms.wrapNodes(editor, link, { split: true })
        Transforms.collapse(editor, { edge: 'end' })
    }
}

const LinkButton = () => {
    const editor = useSlate()
    return (
        <Button
            active={isLinkActive(editor)}
            onMouseDown={event => {
                event.preventDefault()
                const url = window.prompt('Enter the URL of the link:')
                if (!url) return
                insertLink(editor, url)
            }}
        >
            <span className={classnames('material-icons')}>link</span>
        </Button>
    )
}


const BlockButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            active={isBlockActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault()
                toggleBlock(editor, format)
            }}
        >
            <span className={classnames('material-icons')}>{icon}</span>
        </Button>
    )
}

const MarkButton = ({ format, icon }) => {
    const editor = useSlate();
    const isActive = isMarkActive(editor, format);

    return (
        <Button
            active={isActive}
            onMouseDown={event => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            <span className={classnames('material-icons')}>{icon}</span>
        </Button>
    )
}

const withLinks = editor => {
    const { insertData, insertText, isInline } = editor
  
    editor.isInline = element => {
      return element.type === 'link' ? true : isInline(element)
    }
  
    editor.insertText = text => {
      if (text && validator.isURL(text)) {
        wrapLink(editor, text)
      } else {
        insertText(text)
      }
    }
  
    editor.insertData = data => {
      const text = data.getData('text/plain')
  
      if (text && validator.isURL(text)) {
        wrapLink(editor, text)
      } else {
        insertData(data)
      }
    }
  
    return editor
}


const withHtml = editor => {
    const { insertData, isInline, isVoid } = editor
  
    editor.isInline = element => {
      return element.type === 'link' ? true : isInline(element)
    }
  
    editor.isVoid = element => {
      return element.type === 'image' ? true : isVoid(element)
    }
  
    editor.insertData = data => {
      const html = data.getData('text/html')
  
      if (html) {
        const parsed = new DOMParser().parseFromString(html, 'text/html')
        const fragment = deserialize(parsed.body)
        Transforms.insertFragment(editor, fragment)
        return
      }
  
      insertData(data)
    }
  
    return editor
}

export default RichTextInput;
