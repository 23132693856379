import React, { useState } from 'react';
import {
    Card,
    Form,
    Button,
    Alert,
    Tabs,
    Checkbox,
    Row,
    Col
} from 'antd';

import {
    AccordionStep,
} from '../../../../../components';
import {
    form
} from '../../../../../assets/config';
import {
    useFormProcess
} from '../../../../../hooks';

import { hooks as amenitiesHooks } from '../../../../amenities';

const ProjectAmenities = React.memo(({
    loading,
    value: data,
    fns,
    postProject,
    ...props
}) => {

    const [formProcess, formProcessFns] = useFormProcess();
    const amenities = amenitiesHooks.useAmenities();

    const onSubmit = () => {
        postProject(formProcessFns);
    }

    return (
        <AccordionStep {...props} displayName="Amenities">
            <Card loading={loading}>
                <Form {...form.layout}>
                    <Form.Item label="Amenities">
                        <Tabs>
                            {
                                Object.entries(amenities).map(([ key, value]) => {
                                    let amenities = value
                                        .filter((amenity = {})=> amenity.amenity && (amenity.amenity || {}).name)
                                        .map((amenity) => ({ value: amenity._id, label: amenity.amenity.name }));

                                    const selected = (data[key] || []);
                                    return (
                                        <Tabs.TabPane tab={key} key={key}>
                                            <Checkbox.Group
                                                value={selected}
                                                onChange={(v) => fns.setAmenities(v, key)}
                                                style={{
                                                    width: '100%'
                                                }}
                                            >
                                                <Row>
                                                    {amenities.map(amenity => (
                                                        <Col sm={6} key={amenity}>
                                                            <Checkbox value={amenity.value}>{amenity.label}</Checkbox>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Checkbox.Group>
                                        </Tabs.TabPane>
                                    );
                                })
                            }
                        </Tabs>
                    </Form.Item>

                    <Form.Item {...form.tailLayout}>
                        <Button 
                            type="primary"
                            htmlType="submit"
                            loading={formProcess.loading}
                            onClick={onSubmit}
                        >
                            Save
                        </Button>
                    </Form.Item>
                    {formProcess.errorMsg && (
                        <Form.Item {...form.tailLayout}>
                            <Alert message={formProcess.errorMsg} type="error" />
                        </Form.Item>
                    )}
                </Form>
            </Card>
        </AccordionStep>
    )
});

function useProjectAmenities() {

    const [value, setValue] = useState({});

    function updateDataFromAPI(data) {
        let amenities = data.amenities || [];

        let categories = {};

        amenities.filter((amenity ={}) => (amenity.amenity || {}).name).forEach(amenity => {
            if (categories[amenity.category]) {
                categories[amenity.category] = [...categories[amenity.category], amenity._id];
            } else {
                categories[amenity.category] = [amenity._id];
            }
        });

        setValue(categories);
    }

    function setAmenities(v, key) {
        setValue(d => ({
            ...d,
            [key]: v
        }));
    }

    function formatData() {
        let postValues = [];

        Object.values(value).forEach(amenityIds => {
            postValues = [...postValues, ...amenityIds];
        })

        return { amenities: postValues };
    }

    return [
        value,
        {
            updateDataFromAPI,
            formatData,
            setAmenities
        }
    ];
}

ProjectAmenities.useProjectAmenities = useProjectAmenities;

export default ProjectAmenities;
