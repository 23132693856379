import React, { useState } from 'react';
import {
    Card,
    Form,
    Tabs,
    Checkbox,
    Row,
    Col
} from 'antd';

import {
    AccordionStep,
} from '../../../../../components';
import {
    form
} from '../../../../../assets/config';

import { hooks as amenitiesHooks } from '../../../../amenities';

const PropertyAmenities = React.memo(({
    data,
    fns,
    ...props
}) => {
    const amenities = amenitiesHooks.useAmenities();

    return (
        <AccordionStep {...props} displayName="Amenities">
            <Card>
                <Form {...form.layout}>
                    <Form.Item label="Amenities">
                        <Tabs>
                            {
                                Object.entries(amenities).map(([ key, value]) => {
                                    let amenities = value
                                        .filter((amenity = {})=> amenity.amenity && (amenity.amenity || {}).name)
                                        .map((amenity) => ({ value: amenity._id, label: amenity.amenity.name }));

                                    const selected = (data[key] || []);
                                    return (
                                        <Tabs.TabPane tab={key} key={key}>
                                            <Checkbox.Group
                                                value={selected}
                                                onChange={(v) => fns.setAmenities(v, key)}
                                                style={{
                                                    width: '100%'
                                                }}
                                            >
                                                <Row>
                                                    {amenities.map(amenity => (
                                                        <Col sm={6}>
                                                            <Checkbox value={amenity.value}>{amenity.label}</Checkbox>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Checkbox.Group>
                                        </Tabs.TabPane>
                                    );
                                })
                            }
                        </Tabs>
                    </Form.Item>
                </Form>
            </Card>
        </AccordionStep>
    )
});

function usePropertyAmenities() {

    const [value, setValue] = useState({});

    function updateData(data) {
        let amenities = data.amenities || [];

        let categories = {};

        amenities.filter((amenity ={}) => (amenity.amenity || {}).name).forEach(amenity => {
            if (categories[amenity.category]) {
                categories[amenity.category] = [...categories[amenity.category], amenity._id];
            } else {
                categories[amenity.category] = [amenity._id];
            }
        });

        setValue(categories);
    }

    function setAmenities(v, key) {
        setValue(d => ({
            ...d,
            [key]: v
        }));
    }

    function formatData() {
        let postValues = [];

        Object.values(value).forEach(amenityIds => {
            postValues = [...postValues, ...amenityIds];
        })

        return [{ amenities: postValues }, []];
    }

    return [
        value,
        {
            formatData,
            updateData,
            setAmenities
        }
    ];
}

PropertyAmenities.useProjectAmenities = usePropertyAmenities;

export default PropertyAmenities;
