const layout = {
    labelCol: { xs: 24, md: 4 },
    wrapperCol: { offset: 1, xs: 23, md: 18, xl: 12 }
};

const tailLayout = {
    wrapperCol: { offset: 5 }
};
const form = {
    layout,
    tailLayout
}

const banks = [
    'Allahabad Bank',
    'Andhra Bank',
    'Bank of Baroda',
    'Bank of India',
    'Bank of Maharashtra',
    'Canara Bank',
    'Central Bank of India',
    'Corporation Bank',
    'Dena Bank',
    'Indian Bank',
    'Indian Overseas Bank',
    'Oriental Bank of Commerce',
    'Punjab National Bank',
    'Syndicate Bank',
    'State Bank of India',
    'Union Bank of India',
    'United Bank of India',
    'Punjab & Sind Bank',
    'UCO Bank',
    'Vijaya Bank',
    'Axis Bank Limited',
    'Bandhan Bank Limited',
    'Catholic Syrian Bank Limited',
    'City Union Bank Limited',
    'DCB Bank Limited',
    'Dhanlaxmi Bank Limited',
    'Federal Bank Limited',
    'HDFC Bank Limited',
    'ICICI Bank Limited',
    'IndusInd Bank Limited',
    'IDFC Bank Limited',
    'Jammu & Kashmir Bank Limited',
    'Karnataka Bank Limited',
    'Karur Vysya Bank Limited',
    'Kotak Mahindra Bank Limited',
    'Lakshmi Vilas Bank Limited',
    'Nainital Bank Limited',
    'RBL Bank Limited',
    'South Indian Bank Limited',
    'Tamilnad Mercantile Bank Limited',
    'YES Bank Limited'
]

export {
    form,
    banks
}

