import React from 'react';
import { 
    Form,
    Card,
} from 'antd';
import {
    form
} from '../../../../../assets/config';
import {
    AccordionStep
} from '../../../../../components';

const formFields = [
    {
        name: 'parking',
        key: 'parking',
        value: '',
        type: 'radio',
        options: [
            { label: 1, value: 1},
            { label: 2, value: 2},
            { label: 3, value: 3},
            { label: 4, value: 4},
            { label: 5, value: 5},
        ],
        displayName: 'Parking',
        propertyType: 'rental'
    },
    {
        name: 'monthly_rent',
        key: 'monthly_rent',
        value: null,
        type: 'number',
        displayName: 'Monthly Rent',
        propertyType: 'rental',
        required: true
    },
    {
        name: 'security_deposit',
        key: 'security_deposit',
        value: null,
        type: 'number',
        propertyType: 'rental',
        displayName: 'Security Deposit'
    },
    {
        name: 'preferred_tenant_types',
        key: 'preferred_tenant_types',
        value: '',
        options: [
            { label: 'Family', value: 'Family' },
            { label: 'Single Men', value: 'Single Men' },
            { label: 'Single Women', value: 'Single Women' },
            { label: 'Company', value: 'Company' },
        ],
        type: 'checkbox_group',
        propertyType: 'rental',
        displayName: 'Preferred Tenant Type'
    },
    {
        name: 'max_tenants_allowed',
        key: 'max_tenants_allowed',
        value: null,
        type: 'number',
        propertyType: 'rental',
        displayName: 'Max Tenants Allowed'
    },
    {
        name: 'rent_negotiable',
        key: 'rent_negotiable',
        value: null,
        type: 'boolean',
        propertyType: 'rental',
        displayName: 'Rent Negotiable'
    },
    {
        name: 'min_expected_stay',
        key: 'min_expected_stay',
        value: null,
        type: 'number',
        propertyType: 'rental',
        displayName: 'Min Expected Stay (Days)'
    },
    {
        name: 'preferred_tenant_work_type',
        key: 'preferred_tenant_work_type',
        value: '',
        type: 'text',
        propertyType: 'rental',
        displayName: 'Preferred Tenant Work Type'
    },
    {
        name: 'can_manage_site_visit',
        key: 'can_manage_site_visit',
        value: false,
        type: 'boolean',
        propertyType: 'rental',
        displayName: 'Can Manage Site Visit'
    },
    {
        name: 'rented_property_before',
        key: 'rented_property_before',
        value: false,
        type: 'boolean',
        propertyType: 'rental',
        displayName: 'Rented Property Before'
    },
    {
        name: 'currently_living',
        key: 'currently_living',
        value: false,
        type: 'boolean',
        propertyType: 'rental',
        displayName: 'Currently Living'
    },
    {
        name: 'lock_in_period',
        key: 'lock_in_period',
        value: null,
        type: 'number',
        propertyType: 'rental',
        displayName: 'Lock in Period (days)'
    },
    {
        name: 'age_of_property',
        key: 'age_of_property',
        value: null,
        type: 'number',
        propertyType: 'rental',
        displayName: 'Age Of Property (Years)'
    },
    {
        name: 'covered_parking',
        key: 'covered_parking',
        value: '',
        type: 'radio',
        options: [
            { label: 1, value: 1},
            { label: 2, value: 2},
            { label: 3, value: 3},
            { label: 4, value: 4},
            { label: 5, value: 5},
        ],
        propertyType: 'resale',
        displayName: 'Covered Parking',
    },
    {
        name: 'open_parking',
        key: 'open_parking',
        value: '',
        type: 'radio',
        propertyType: 'resale',
        options: [
            { label: 0, value: 0},
            { label: 1, value: 1},
            { label: 2, value: 2},
            { label: 3, value: 3},
            { label: 4, value: 4},
            { label: 5, value: 5},
        ],
        displayName: 'Open Parking'
    },
    {
        name: 'rera_id',
        key: 'rera_id',
        value: '',
        type: 'text',
        propertyType: 'resale',
        displayName: 'RERA id',
        required: true
    },
    {
        name: 'price',
        key: 'price',
        value: null,
        type: 'number',
        propertyType: 'resale',
        displayName: 'Price',
        required: true
    },
    {
        name: 'emi_price',
        key: 'emi_price',
        value: null,
        type: 'number',
        propertyType: 'resale',
        displayName: 'EMI Price',
    }
]

const PropertySpecification = React.memo(({ data, fns, propertyType, ...props }) => {
    return (
        <AccordionStep {...props} displayName="Property Specification">
            <Card>
                <Form {...form.layout}>
                    {
                        data.filter(field => {
                            return field.propertyType === propertyType;
                        }).map(field => {
                            return fns.getFormItem(field.name)
                        })
                    }
                </Form>
            </Card>
        </AccordionStep>
    )
});

PropertySpecification.formFields = formFields

export default PropertySpecification;
