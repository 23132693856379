import React, { useState } from 'react';
import slugify from 'slugify';
import { 
    Form,
    Card,
    Checkbox,
    Input,
    Radio,
    Button,
    Alert,
    Row,
    Col,
} from 'antd';
import {
    form
} from '../../../../../assets/config';
import {
    AccordionStep,
    Upload
} from '../../../../../components';
import { useFormProcess } from '../../../../../hooks';

const collections = [
    "Featured",
    "Trending",
    "Top-Search",
];

const priceCollection = [
    { value: "Affordable", label: "Affordable" },
    { value: "Luxury", label: "Luxury" },
    { value: "Ultra-Luxury", label: "Ultra-Luxury" },
    { value: "No-Collection", label: "No-Collection" },
]

const ProjectMeta = React.memo(({ loading, value, fns, postProject, ...props }) => {
    const [formProcess, formProcessFns] = useFormProcess();

    const onSubmit = () => {
        postProject(formProcessFns);
    }

    return (
        <AccordionStep {...props} displayName="Project Meta">
            <Card loading={loading}>
                <Form {...form.layout}>
                    <Form.Item label="Title">
                        <Input
                            value={value.title}
                            onChange={fns.setTitle}
                        />
                    </Form.Item>
                    <Form.Item label="Slug">
                        <Input
                            value={value.slug}
                            onChange={fns.setSlug}
                        />
                    </Form.Item>
                    <Form.Item label="Meta Title">
                        <Input
                            value={value.meta_title}
                            onChange={fns.setMetaTitle}
                        />
                    </Form.Item>
                    <Form.Item label="Meta Description">
                        <Input
                            value={value.meta_description}
                            onChange={fns.setMetaDescription}
                        />
                    </Form.Item>
                    <Form.Item label="Residency Type">
                        <Radio.Group
                            value={value.residency_type}
                            onChange={fns.setResidencyType}
                            options={[
                                { label: 'Residential', value: 'Residential' },
                                { label: 'Commercial', value: 'Commercial' }
                            ]}
                            optionType="button"
                        />
                    </Form.Item>
                    <Form.Item label="Property Type">
                        <Radio.Group
                            value={value.property_type}
                            onChange={fns.setPropertyType}
                            options={[
                                { label: 'Apartment', value: 'Apartment', type: 'Residential' },
                                { label: 'Villa', value: 'Villa', type: 'Residential' },
                                { label: 'High Rise Apartment', value: 'High Rise Apartment', type: 'Residential' },
                                { label: 'Low Rise Apartment', value: 'Low Rise Apartment', type: 'Residential' },
                                { label: 'Plot', value: 'Plot', type: 'Residential' },
                                { label: 'Studio', value: 'Studio', type: 'Residential'},
                                { label: 'Pent House', value: 'Pent House', type: 'Residential' },
                                { label: 'Office Space', value: 'Office Space', type: 'Commercial' },
                                { label: 'Co-working space', value: 'Co-working space', type: 'Commercial' },
                                { label: 'Commercial Shops', value: 'Commercial Shops', type: 'Commercial' },
                                { label: 'Commercial Showrooms', value: 'Commercial Showrooms', type: 'Commercial' },
                                { label: 'Commercial Land', value: 'Commercial Land', type: 'Commercial' },
                                { label: 'Industrial Land', value: 'Industrial Land', type: 'Commercial' },
                                { label: 'Industrial Plots', value: 'Industrial Plots', type: 'Commercial' },
                                { label: 'Agricultural/Farm Land', value: 'Agricultural/Farm Land', type: 'Commercial' },
                                { label: 'Hotel/Resorts', value: 'Hotel/Resorts', type: 'Commercial' },
                                { label: 'Guest-House/Banquet-Halls', value: 'Guest-House/Banquet-Halls', type: 'Commercial' },
                                { label: 'Space in Retail Mall', value: 'Space in Retail Mall', type: 'Commercial' },
                                { label: 'Ware House', value: 'Ware House', type: 'Commercial' },
                                { label: 'Cold Storage', value: 'Cold Storage', type: 'Commercial' },
                                { label: 'Factory', value: 'Factory', type: 'Commercial' },
                                { label: 'Manufacturing', value: 'Manufacturing', type: 'Commercial' }
                            ].filter(i => i.type === value.residency_type || !value.residency_type)}
                            optionType="button"
                        />
                    </Form.Item>
                    <Form.Item label="Collection based on price">
                        <Radio.Group
                            value={value.priceCollection}
                            onChange={fns.setPriceCollection}
                            options={priceCollection}
                            optionType="button"
                        />
                    </Form.Item>
                    <Form.Item label="Collections">
                        <Checkbox.Group
                            value={value.tags}
                            onChange={fns.setTags}
                        >
                            <Row>
                                {collections.map(collection => (
                                    <Col sm={16} key={collection}>
                                        <Checkbox value={collection}>{collection}</Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item label="Video Link">
                        <Input.TextArea
                            rows={4}
                            value={value.videoLink}
                            onChange={fns.setVideoLink}
                        />
                    </Form.Item>
                    <Form.Item label="Gallery">
                        <Upload
                            fileList={value.gallery}
                            setFileList={fns.setGallery}
                            aspectRatio={1.77778}
                        />
                    </Form.Item>
                    <Form.Item label="Feature Image">
                        <Upload
                            limit={1}
                            fileList={value.featured_image}
                            setFileList={fns.setFeatured}
                            aspectRatio={1.77778}
                        />
                    </Form.Item> 
                    <Form.Item {...form.tailLayout}>
                        <Button 
                            type="primary"
                            htmlType="submit"
                            onClick={onSubmit}
                            loading={formProcess.loading}
                        >
                            Save
                        </Button>
                    </Form.Item>
                    <Form.Item {...form.tailLayout}>
                        {formProcess.errorMsg && (
                            <Alert message={formProcess.errorMsg} type="error" />
                        )}
                    </Form.Item>
                </Form>
            </Card>
        </AccordionStep>
    )
});

function useProjectMeta() {
    const [value, setValue] = useState({
        title: '',
        slug: '',
        meta_title: '',
        meta_description: '',
        residency_type: '',
        property_type: '',
        tags: [],
        priceCollection: '',
        gallery: [],
        featured_image: []
    });

    function setTitle(e) {
        const value = e.target.value;
        setValue(d => ({ ...d, title: value }));
    }

    function setSlug(e) {
        const value = e.target.value;
        setValue(d => ({ ...d, slug: value }));
    }

    function setMetaTitle(e) {
        const value = e.target.value;
        setValue(d => ({ ...d, meta_title: value }));
    }
    function setMetaDescription(e) {
        const value = e.target.value;
        setValue(d => ({ ...d, meta_description: value }));
    }
    function setResidencyType(e) {
        const value = e.target.value;
        setValue(d => ({ ...d, residency_type: value }));
    }

    function setPropertyType(e) {
        const value = e.target.value;
        setValue(d => ({ ...d, property_type: value }));
    }

    function setPriceCollection(e) {
        const value = e.target.value;
        setValue(d => ({ ...d, priceCollection: value }));
    }

    function setTags(tags) {
        setValue(d => ({ ...d, tags}));
    }

    function setGallery(gallery) {
        setValue(d => ({ ...d, gallery }))
    }

    function setFeatured(featured) {
        setValue(d => ({ ...d, featured_image: featured }));
    }

    function setVideoLink(e) {
        const value = e.target.value;

        const finder = /src="([\S]*)"/;
        const v = value.match(finder)?.[1] || '';


        const newIframe = v ? `<iframe width="100%" height="100%" src="${v}" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />` : '';

        setValue(d => ({ ...d, videoLink: newIframe }))
    }

    function updateDataFromAPI(data) {
        let gallery = data.gallery || [];

        const parsedGallery = gallery.map(d => ({
            uid: d._id,
            url: d.location,
            response: {
                location: d.location,
                key: d.image_key || d._id
            }
        }));

        let parsedFeatured = [];
        
        if (data.featured_image && (data.featured_image || {}).location) {
            const ft = data.featured_image || {};
            parsedFeatured = [{
                url: ft.location,
                uid: ft.image_key,
                response: {
                    location: ft.location,
                    image_key: ft.image_key
                }
            }];
        }

        const tags = (data.tags || []).map((tag) => {
            return tag.tag;
        })

        const priceCollection = (data.tags || []).find(tag => {
            return ['Affordable', 'Luxury', 'Ultra-Luxury', 'No-Collection'].includes(tag.tag);
        })

        setValue({
            title: data.title || '',
            slug: data.slug || '',
            meta_title: data.meta_title || '',
            meta_description: data.meta_description || '',
            residency_type: data.residency_type || '',
            property_type: data.property_type || '',
            videoLink: data.videoLink || '',
            gallery: parsedGallery,
            priceCollection: (priceCollection || {}).tag || "No-Collection",
            featured_image: parsedFeatured,
            tags: tags
        });
    }

    function formatData() {
        let featured_image = {};

        if (value.featured_image[0] && value.featured_image[0].location) {
            let ft = value.featured_image[0];

            featured_image = {
                image_key: ft.image_key,
                location: ft.location
            }
        }

        let slug = slugify(value.slug.toLowerCase().trim());

        let tags = value.tags.map(tag => ({ tag, status: true }));
        
        tags = tags.filter(tag => {
            return !["Affordable", "Luxury", "Ultra-Luxury", "No-Collection"].includes(tag.tag)
        })

        if (value.priceCollection) {
            tags = [...tags, { tag: value.priceCollection, status: true }];
        }


        return {
            ...value,
            priceCollection: undefined,
            slug,
            tags,
            gallery: value.gallery.map(({ response: image }) => ({
                image_key: image.key,
                location: image.location
            })),
            featured_image
        }
    }

    return [
        value,
        {
            setTitle,
            setSlug,
            setMetaTitle,
            setMetaDescription,
            setPriceCollection,
            setResidencyType,
            setPropertyType,
            setTags,
            setGallery,
            setVideoLink,
            setFeatured,
            updateDataFromAPI,
            formatData
        }
    ];
}

ProjectMeta.useProjectMeta = useProjectMeta;

export default ProjectMeta;
