import React from 'react';
import { Steps, Collapse } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

const AccordionStep = ({ displayName, children, ...props }) => {
    return (
        <Steps.Step
            {...props}
            key={displayName}
            description={
                <Collapse
                    accordion
                    expandIconPosition="right"
                    expandIcon={(props) => {
                        if (props.isActive) {
                            return <MinusCircleOutlined style={{ fontSize: 24 }} />;
                        }
                        return <PlusCircleOutlined style={{ fontSize: 24 }} />;
                    }}
                >
                    <Collapse.Panel header={displayName}>
                        {children}
                    </Collapse.Panel>
                </Collapse>
            }
        />
    );
}

export default AccordionStep;