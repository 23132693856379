import React, { useState } from 'react';
import {
  Card,
  Form,
  Input,
  Select,
  Button,
  Alert
} from 'antd';
import validator from 'validator';
import {
  AccordionStep
} from '../../../../../components';
import {
  form
} from '../../../../../assets/config';
import {
  embedLink,
  getGoogleMapsIframeSrc
} from '../../../../../assets/fns';
import {
  useFormProcess
} from '../../../../../hooks';


const Location = React.memo(({
  loading,
  value,
  cities,
  localities,
  fns,
  postProject,
  ...props
}) => {
  const [formProcess, formProcessFns] = useFormProcess();

  const onSubmit = () => {
    postProject(formProcessFns);
  }

  return (
    <AccordionStep displayName="Location" {...props}>
      <Card loading={loading}>
        <Form {...form.layout}>
          <Form.Item
            label="Iframe"
          >
            <Input
              value={value.iframe}
              onChange={fns.setIframe}
            />
          </Form.Item>
          <Form.Item
            label="Cord"
            extra={(
              <>
                <a href={`https://maps.google.com/?q=${value?.cord?.[1]},${value?.cord?.[0]}`} rel="noopener noreferrer">{value.cord.join(',')}</a>
              </>)
            }
          >
            <Input
              disabled
              value={value.coordinates}
              onChange={fns.setLocation}
            />
          </Form.Item>
          <Form.Item label="Address">
            <Input.TextArea
              rows={4}
              value={value.address}
              onChange={fns.setAddress}
            />
          </Form.Item>
          <Form.Item label="City">
            <Select
              placeholder="Select a option and change input text above"
              allowClear
              value={value.city}
              onChange={fns.setCity}
            >
              {cities.map(city => (
                <Select.Option value={city}>{city}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Localities">
            <Select
              placeholder="Select a option and change input text above"
              allowClear
              value={value.localityId}
              onChange={fns.setLocalityId}
            >
              {
                localities.filter(locality => locality.city === value.city).map(locality => (
                  <Select.Option
                    value={locality._id}
                  >
                    {locality.locality}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item {...form.tailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              loading={formProcess.loading}
              onClick={onSubmit}
            >
              Save
            </Button>
          </Form.Item>

          <Form.Item {...form.tailLayout}>
            {formProcess.errorMsg && (
              <Alert message={formProcess.errorMsg} type="error" />
            )}
          </Form.Item>

        </Form>
      </Card>
    </AccordionStep>
  );
})

function useLocation() {
  const [value, setValue] = useState({
    coordinates: '',
    iframe: '',
    cord: [],
    city: '',
    localityId: '',
    address: ''
  });

  function updateDataFromAPI(data) {
    const locality = data.locality || {};
    const cord = ((data.location || {}).coordinates || [0, 0]);
    const locIframe = ((data.location || {}).iframe);

    setValue({
      coordinates: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1903.1863253095796!2d${cord[0] || ''}!3d${cord[1] || ''}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb915cf23132cf%3A0xa1d3cfe0aab7fc94!2sMontaigne%20Smart%20Business%20Solutions!5e0!3m2!1sen!2sin!4v1602111618782!5m2!1sen!2sin" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`,
      cord,
      iframe: locIframe,
      city: locality.city,
      localityId: locality._id,
      address: data.address
    })
  }

  function setIframe(e) {
    const value = e.target.value;
    const cord = embedLink(value);
    setValue(d => ({ ...d, iframe: value, coordinates: value, cord }));
  }

  function setLocation(e) {
    const value = e.target.value;
    const cord = embedLink(value);
    setValue(d => ({ ...d, coordinates: value, cord }));
  }

  function setAddress(e) {
    const value = e.target.value;
    setValue(d => ({ ...d, address: value }));
  }

  function setCity(city) {
    setValue(d => ({ ...d, localityId: '', city }))
  }

  function setLocalityId(localityId) {
    setValue(d => ({ ...d, localityId }))
  }

  function formatData() {
    const { cord, iframe, address, localityId: locality } = value;

    const _postData = {
      address,
      locality
    };

    const [lat, long] = cord;

    const isCordNull = lat == null && long == null;

    if (isCordNull || validator.isLatLong(cord.join(','))) {
      _postData.location = {
        iframe: iframe,
        coordinates: cord
      }
    }

    return _postData;
  }

  return [
    value,
    {
      updateDataFromAPI,
      setAddress,
      setCity,
      setIframe,
      setLocalityId,
      setLocation,
      formatData
    }
  ];
}

Location.useLocation = useLocation;

export default Location;
