import React, { useState } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Card, Image } from 'antd';
import { useHistory } from 'react-router-dom';

import { login } from './login.api';

const OuterContainer = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    width: 400px;
    height: 400px;

    .error_msg {
        color: red;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    justify-contents: center;
    align-items: center;
    margin-bottom: 3.2rem;
`

const Login = () => {
    const onFinish = values => {
        console.log('Received values of form: ', values);
    };


    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    if (localStorage.getItem('token')) {
        history.push('/projects')
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        login(username, password)
            .then((res) => {
                if (res.status === 200) {
                    localStorage.setItem('token', res.token);
                    localStorage.setItem('username', res.username);
                    history.push('/projects');
                } else if (res.status === 400) {
                    setError(res.error)
                }
            })
            .catch(() => {

            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <OuterContainer>
            <Container>
                <Card style={{ height: '410px' }}>
                    <ImageContainer>
                        <Image
                            width="168px"
                            src="/investo-xpert-logo.png"
                            style={{ margin: 'auto auto' }}
                        />
                    </ImageContainer>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please input your Email!', type: "email" }]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Username"
                                value={username}
                                size="large"
                                onChange={e => {
                                    const value = e.target.value;
                                    setUsername(value);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                                value={password}
                                size="large"
                                onChange={e => {
                                    const value = e.target.value;
                                    setPassword(value);
                                }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>

                            <a className="login-form-forgot" href="/">
                                Forgot password
                            </a>
                        </Form.Item>

                        <div><p className="error_msg">{error}</p></div>

                        <Form.Item>
                            <Button type="primary" size="large" loading={loading} onClick={onSubmit} block={true}>
                                Log In
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Container>
        </OuterContainer>
    );
};

export default Login;