import { useState, useEffect } from 'react';
import { getAmenities } from '../api';

function useAmenities() {
    const [amenities, setAmenities] = useState({});
    const [errorCount, setErrorCount] = useState(0);

    useEffect(() => {
        if (errorCount > 3) {
            return;
        }

        getAmenities()
            .then(res => {
                if (res.status === 200) {
                    let categories = { };
                    (res.data || []).forEach((amenity = {})=> {
                        if (categories[amenity.category]) {
                            categories[amenity.category] = [...categories[amenity.category], {...amenity}];
                        } else {
                            categories[amenity.category] = [{ ...amenity }];
                        }
                    });
                    setAmenities(categories);
                } else {
                    throw new Error('Status not handled');
                }
            }).catch(err => {
                setErrorCount(c => c + 1);
            })
    }, [errorCount])

    return amenities;
}

export default useAmenities;
