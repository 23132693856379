import React from 'react';
import {
    DeleteOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import { Space, Button, Modal } from 'antd';

import { deleteLeads } from '../api';

export const leadColumns = [
    {
        title: 'Name',
        key: 'name',
        fixed: true,
        dataIndex: 'name'
    },
    {
        title: 'Phone',
        key: 'phone',
        dataIndex: 'phone'
    },
    {
        title: 'email',
        key: 'email',
        dataIndex: 'email'
    },
    {
        title: 'Project',
        key: 'project',
        dataIndex: 'project'
    },
    {
        title: 'Filled On',
        key: 'visit_location',
        dataIndex: 'visit_location'
    },
    {
        title: 'Time',
        key: 'time',
        dataIndex: 'created_at'
    },
    {
        title: 'City',
        key: 'city',
        dataIndex: 'city'
    },
    {
        title: 'Remark',
        key: 'remark',
        dataIndex: 'remark'
    },
    {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        render: (props) => {
            const slug = props.slug;


            function showPromiseConfirm() {
                Modal.confirm({
                  title: 'Do you want to delete lead?',
                  icon: <ExclamationCircleOutlined />,
                  content: `Press OK to delete lead from ${props.name}`,
                  onOk() {
                    return deleteLeads([props._id]);
                  },
                  onCancel() {},
                });
              }

            return (
                <Space>
                    <Button 
                        type="ghost" 
                        danger
                        icon={<DeleteOutlined />}
                        onClick={showPromiseConfirm}
                    >
                        Delete
                    </Button>
                </Space>
            )
        }
    }
];
