import React from 'react';
import { 
    Form,
    Card,
    Select
} from 'antd';
import {
    form
} from '../../../../../assets/config';
import {
    AccordionStep
} from '../../../../../components';

const formFields = [
    {
        name: 'location',
        key: 'location',
        value: '',
        cord: [],
        type: 'location',
        displayName: 'location',
        required: true
    },
    {
        name: 'city',
        key: 'city',
        value: null,
        type: 'select',
        options: [],
        displayName: 'City',
        required: true
    },
    {
        name: 'locality',
        key: 'locality',
        value: null,
        type: 'select',
        options: [],
        renderOptions: (options) => {
            return null
        },
        displayName: 'Locality',
        required: true
    }
]

const PropertyLocation = React.memo(({ data, fns, cities, localities, ...props }) => {

    return (
        <AccordionStep {...props} displayName="Property Location">
            <Card>
                <Form {...form.layout}>
                    {data.map(field => {
                        if (field.name === 'city') {
                            return (
                                <Form.Item {...fns.getItemProps(field)}>
                                    <Select
                                        value={field.value}
                                        onChange={value => fns.setFieldValue(field, value)}
                                    >
                                        {cities.map(city => (
                                            <Select.Option value={city}>{city}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )
                        }

                        if (field.name === 'locality') {
                            return (
                                <Form.Item {...fns.getItemProps(field)}>
                                    <Select
                                        value={field.value}
                                        onChange={value => fns.setFieldValue(field, value)}
                                    >
                                        {localities.filter(locality => locality.city === fns.getFieldValue('city')).map(locality => (
                                            <Select.Option value={locality._id}>{locality.locality}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )
                        }

                        return fns.getFormItem(field.name)
                    })}
                </Form>
            </Card>
        </AccordionStep>
    )
});

PropertyLocation.formFields = formFields;

export default PropertyLocation;
