import React from 'react';
import Styled from 'styled-components';
import { Table } from 'antd';
import { RichTextInput } from '../../../../../../components';

const EditableCell = ({
  children,
  isEditable,
  isRich,
  record,
  title,
  dataIndex,
  handleSave,
  ...rest
}) => {

  let childNodes = children;

  if (isEditable) {
    childNodes = (
      <StyledEditableCell>
        <textarea 
          placeholder={title}
          defaultValue={record[dataIndex]}
          onChange={e => handleSave(e.target.value, record.key, dataIndex)}
          />
      </StyledEditableCell>
    );
  }
  
  // if (isRich) {
  //   childNodes = (
  //     <StyledEditableCell>
  //      {/* <RichTextInput
  //           value={record[dataIndex] || ''}
  //           onChange={(value) => handleSave(value, record.key, dataIndex)}
  //       /> */}
  //     </StyledEditableCell>
  //   );
  // }

  return <td {...rest}>{childNodes}</td>
}

const MetaTable = ({ updateRecord, data, status }) => {

  let _columns = columns.map(col => {
    if (!col.isEditable && !col.isRich) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        isEditable: col.isEditable,
        isRich: col.isRich,
        title: col.title,
        dataIndex: col.dataIndex,
        handleSave: updateRecord
      })
    }
  });

  return (
    <TableContainer>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        columns={_columns}
        dataSource={data}
        pagination={false}
        loading={status.loading}
      />
    </TableContainer>
  );
}

const columns = [
  {
    title: 'URL',
    dataIndex: 'url',
    width: '15%'
  },
  {
    title: 'Meta Title',
    dataIndex: 'meta-title',
    width: '15%',
    isEditable: true
  },
  {
    title: 'Meta Description',
    dataIndex: 'meta-description',
    width: '35%',
    isEditable: true
  },
  {
    title: 'Content',
    width: '35%',
    dataIndex: 'content',
    isEditable: true
  }
]

const StyledEditableCell = Styled.div`
  padding: 0;
  width: 100%; height: 100%;
  textarea {
    margin: 0;
    background-color: #f5f5f5;
    padding: 0.5rem 0.5rem;
    border: none;
    width: 100%;
    height: 100%;
  }
`

const TableContainer = Styled.div`
  margin: 2rem 2rem;
`;

export default MetaTable;
