import { useState } from 'react';

function useFormProcess() {
    const [formProcess, setFormProcess]  = useState({
        loading: false,
        errorMsg: ''
    })

    function setLoading(loading) {
        setFormProcess(d => ({ ...d, loading }))
    }

    function setErrorMessage(errorMsg) {
        setFormProcess(d => ({ ...d, errorMsg }))
    }

    return [formProcess, {
        setLoading,
        setErrorMessage
    }];
}

export default useFormProcess;
