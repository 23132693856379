import React, { useState, createRef } from 'react';
import {
    Card,
    Form,
    Input,
    Button,
    Slider,
    Rate,
    DatePicker,
    Select,
    Alert
} from 'antd';
import moment from 'moment';
import {
    HeartOutlined,
    StarFilled
} from '@ant-design/icons';

import {
    AccordionStep,
    RichTextInput,
} from '../../../../../components';
import {
    form
} from '../../../../../assets/config';
import {
    disabledDate
} from '../../../../../assets/fns';
import {
    useFormProcess
} from '../../../../../hooks';

const ProjectBasicInformation = React.memo(({
    loading,
    value,
    fns,
    postProject,
    builders,
    ...props
}) => {

    const [formProcess, formProcessFns] = useFormProcess();

    const onSubmit = () => {
        postProject(formProcessFns);
    }

    return (
        <AccordionStep {...props} displayName="Basic Information">
            <Card loading={loading}>
                <Form {...form.layout}>
                    {/* <Form.Item label="Location">
                        <Drawer
                            closable={false}
                            onClose={closeLocationDrawer}
                            visible={showLocationDrawer}
                            width="20rem"
                        >
                            <Space direction="vertical">
                                <p> 
                                    Please enter the URL copied from the 
                                    google map page for the given location
                                </p>
                                <Input />
                                <Button type="primary">
                                    Update
                                </Button>
                            </Space>
                        </Drawer>
                    </Form.Item> */}
                    <Form.Item label="Rera ID">
                        <Input
                            value={value.rear_id}
                            onChange={fns.setReraId}
                        />
                    </Form.Item>
                    <Form.Item label="Price Per Square Feet">
                        <Input
                            type="number"
                            value={value.price_per_sq_ft}
                            onChange={fns.setPricePerSquareFeet}
                        />
                    </Form.Item>
                    <Form.Item label="Min Price">
                        <Input
                            type="number"
                            value={value.minimum_price}
                            onChange={fns.setMinPrice}
                        />
                    </Form.Item>
                    <Form.Item label="Max Price">
                        <Input
                            type="number"
                            value={value.maximum_price}
                            onChange={fns.setMaxPrice}
                        />
                    </Form.Item>
                    <Form.Item label="Builder">
                        <Select
                            placeholder="Select a option and change input text above"
                            allowClear
                            value={value.builder}
                            onChange={fns.setBuilder}
                        >
                            {builders.map((builder) => {
                                return <Select.Option key={builder._id} value={builder._id}>{builder.name}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Posession Date">
                        <DatePicker
                            value={ value.possession_date ? moment(value.possession_date) : ''}
                            onChange={fns.setPosessionDate}
                        />
                    </Form.Item>
                    <Form.Item label="Posession Status">
                        <Select
                            placeholder="Select a option and change input text above"
                            onChange={fns.setPosessionStatus}
                            value={value.possession_status}
                            allowClear
                        >
                            <Select.Option value="New Launch">New Launch</Select.Option>
                            <Select.Option value="Mid Stage">Mid Stage</Select.Option>
                            <Select.Option value="Near Possession">Near Posession</Select.Option>
                            <Select.Option value="Ready To Move">Ready To Move</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Posession Bar Progress">
                        <Slider
                            min={1}
                            max={10}
                            step={1}
                            value={value.possession_value}
                            // onChange={fns.setPosessionValue}
                        />
                    </Form.Item>

                    <Form.Item label="Rating">
                        <Rate
                            defaultValue={null}
                            count={5}
                            character={<StarFilled />}
                            value={value.rating}
                            onChange={fns.setRating}
                        />
                    </Form.Item>

                    <Form.Item label="Connectivity">
                        <Rate
                            defaultValue={null}
                            count={10}
                            character={<HeartOutlined />}
                            value={value.connectivity}
                            onChange={fns.setConnectivity}
                        />
                    </Form.Item>
                    <Form.Item label="Value For Money">
                        <Rate
                            defaultValue={null}
                            count={10}
                            character={<HeartOutlined />}
                            value={value.value_for_money}
                            onChange={fns.setValueForMoney}
                        />
                    </Form.Item>
                    <Form.Item label="Livability">
                        <Rate
                            defaultValue={null}
                            count={10}
                            character={<HeartOutlined />}
                            value={value.livability}
                            onChange={fns.setLivability}
                        />
                    </Form.Item>
                    <Form.Item label="Life Style">
                        <Rate
                            defaultValue={null}
                            count={10}
                            character={<HeartOutlined />}
                            value={value.life_style}
                            onChange={fns.setLifeStyle}
                        />
                    </Form.Item>
                    <Form.Item label="Project Highlights">
                        <RichTextInput
                            value={value.highlightSaved}
                            onChange={fns.updateHighlight}
                            />
                    </Form.Item>
                    <Form.Item label="About Project">
                        <RichTextInput
                            value={value.descriptionSaved}
                            onChange={fns.updateDescription}
                        />
                    </Form.Item>
                    <Form.Item {...form.tailLayout}>
                        <Button 
                            type="primary"
                            htmlType="submit"
                            loading={formProcess.loading}
                            onClick={onSubmit}
                        >
                            Save
                        </Button>
                    </Form.Item>
                    <Form.Item {...form.tailLayout}>
                        {formProcess.errorMsg && (
                            <Alert message={formProcess.errorMsg} type="error" />
                        )}
                    </Form.Item>
                </Form>
            </Card>
        </AccordionStep>
    )
});

const highlightRef = createRef();
const descriptionRef = createRef();

function useProjectBasicInformation() {

    const [value, setValue] = useState({
        rear_id: '',
        price_per_sq_ft: '',
        minimum_price: '',
        maximum_price: '',
        possession_date: '',
        possession_value: '',
        possession_status: '',
        rating: '',
        connectivity: '',
        value_for_money: '',
        livability: '',
        life_style: '',
        highlight: '',
        description: '',
        builder: ''
    });

    function updateDataFromAPI(data) {
        const decisionDrivers = data.decision_drivers || {};
        setValue({
            rear_id: data.rear_id || '',
            price_per_sq_ft: data.price_per_sq_ft || '',
            minimum_price: data.minimum_price || '',
            maximum_price: data.maximum_price || '',
            connectivity: decisionDrivers.connectivity || '',
            value_for_money: decisionDrivers.value_for_money || '',
            life_style: decisionDrivers.life_style || '',
            livability: decisionDrivers.livability || '',
            highlightSaved: data.highlight || '',
            descriptionSaved: data.description || '',
            possession_date: data.possession_date || '',
            possession_value: data.possession_value || '',
            possession_status: data.possession_status || '',
            builder: data.builder || '',
            rating: data.rating || ''
        });
    }

    function setBuilder(value) {
        setValue(d => ({ ...d, builder: value }));
    }

    function setReraId(e) {
        const value = e.target.value;
        setValue(d => ({ ...d, rear_id: value }));
    }

    function setPricePerSquareFeet(e) {
        const value = e.target.value;
        setValue(d => ({ ...d, price_per_sq_ft: value }));
    }

    function setMinPrice(e) {
        const value = e.target.value;
        setValue(d => ({ ...d, minimum_price: value }));
    }

    function setMaxPrice(e) {
        const value = e.target.value;
        setValue(d => ({ ...d, maximum_price: value }));
    }

    function setPosessionDate(e) {
        const value = e ? e.toISOString() : null;
        setValue(d => ({ ...d, possession_date: value }));
    }

    function setPosessionStatus(status) {
        const posessionValue = {
            'New Launch': 3,
            'Mid Stage': 5,
            'Near Possession': 8,
            'Ready To Move': 10
        };

        setValue(d => ({ ...d, possession_status: status, possession_value: posessionValue[status] }));
    }
    function setPosessionValue(value){
        setValue(d => ({ ...d, possession_value: value }));
    }

    function setRating(value) {
        setValue(d => ({ ...d, rating: value}));
    }

    function setConnectivity(value){
        setValue(d => ({ ...d, connectivity: value }))
    }

    function setValueForMoney(value) {
        setValue(d => ({ ...d, value_for_money: value }))
    }

    function setLivability(value) {
        setValue(d => ({ ...d, livability: value }))
    }

    function setLifeStyle(value) {
        setValue(d => ({ ...d, life_style: value }))
    }

    function updateHighlight(value) {
        highlightRef.current = value;
    }

    function updateDescription(value) {
        descriptionRef.current = value;
    }

    function formatData() {
        const {
            highlightSaved,
            descriptionSaved,
            connectivity,
            livability,
            life_style,
            value_for_money,
            ..._postData
        } = value;

        return {
            ..._postData,
            possession_date: _postData.possession_date || '',
            rear_id: _postData.rear_id || '',
            highlight: highlightRef.current || '',
            description: descriptionRef.current || '',
            decision_drivers: {
                connectivity,
                livability,
                life_style,
                value_for_money
            }
        }
    }

    return [
        value,
        {
            updateDataFromAPI,
            setReraId,
            setPricePerSquareFeet,
            setMinPrice,
            setMaxPrice,
            setPosessionDate,
            setPosessionStatus,
            setPosessionValue,
            setRating,
            setConnectivity,
            setValueForMoney,
            setLivability,
            setLifeStyle,
            formatData,
            updateHighlight,
            updateDescription,
            setBuilder
        }
    ];
}

ProjectBasicInformation.useProjectBasicInformation = useProjectBasicInformation;

export default ProjectBasicInformation;
