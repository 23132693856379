import React from 'react';
import { 
    Form,
    Card,
    Radio
} from 'antd';
import {
    form
} from '../../../../../assets/config';
import {
    AccordionStep
} from '../../../../../components';

const formFields = [
    {
        name: 'title',
        key: 'title',
        value: '',
        type: 'text',
        displayName: 'Title',
        required: true
    },
    {
        name: 'slug',
        key: 'slug',
        value: '',
        type: 'text',
        displayName: 'Slug',
        required: true
    },
    {
        name: 'meta_title',
        key: 'meta_title',
        value: '',
        type: 'text',
        displayName: 'Meta Title',
        required: true
    },
    {
        name: 'meta_description',
        key: 'meta_description',
        value: '',
        type: 'text',
        displayName: 'Meta Description',
        required: true
    },
    {
        name: 'residency_type',
        key: 'residency_type',
        value: '',
        type: 'radio',
        optionType: 'button',
        displayName: 'Residency Type',
        options: [
            { label: 'Residential', value: 'Residential' },
            { label: 'Commercial', value: 'Commercial' }
        ],
        required: true
    },
    {
        name: 'property_type',
        key: 'property_type',
        value: '',
        type: 'radio',
        optionType: 'button',
        displayName: 'Property Type',
        options: [
            { label: 'Apartment', value: 'Apartment', type: 'Residential' },
            { label: 'Villa', value: 'Villa', type: 'Residential' },
            { label: 'High Rise Apartment', value: 'High Rise Apartment', type: 'Residential' },
            { label: 'Low Rise Apartment', value: 'Low Rise Apartment', type: 'Residential' },
            { label: 'Plot', value: 'Plot', type: 'Residential' },
            { label: 'Studio', value: 'Studio', type: 'Residential'},
            { label: 'Pent House', value: 'Pent House', type: 'Residential' },
            { label: 'Office Space', value: 'Office Space', type: 'Commercial' },
            { label: 'Co-working space', value: 'Co-working space', type: 'Commercial' },
            { label: 'Commercial Shops', value: 'Commercial Shops', type: 'Commercial' },
            { label: 'Commercial Showrooms', value: 'Commercial Showrooms', type: 'Commercial' },
            { label: 'Commercial Land', value: 'Commercial Land', type: 'Commercial' },
            { label: 'Industrial Land', value: 'Industrial Land', type: 'Commercial' },
            { label: 'Industrial Plots', value: 'Industrial Plots', type: 'Commercial' },
            { label: 'Agricultural/Farm Land', value: 'Agricultural/Farm Land', type: 'Commercial' },
            { label: 'Hotel/Resorts', value: 'Hotel/Resorts', type: 'Commercial' },
            { label: 'Guest-House/Banquet-Halls', value: 'Guest-House/Banquet-Halls', type: 'Commercial' },
            { label: 'Space in Retail Mall', value: 'Space in Retail Mall', type: 'Commercial' },
            { label: 'Ware House', value: 'Ware House', type: 'Commercial' },
            { label: 'Cold Storage', value: 'Cold Storage', type: 'Commercial' },
            { label: 'Factory', value: 'Factory', type: 'Commercial' },
            { label: 'Manufacturing', value: 'Manufacturing', type: 'Commercial' }
        ],
        required: true
    },
    {
        name: "priceCollection",
        key: "priceCollection",
        options: ["Affordable", "Luxury", "Ultra-Luxury", "No-Collection"],
        type: "radio",
        optionType: "button",
        displayName: "Collection based on price"
    },
    {
        name: 'tags',
        key: 'tags',
        options: [
            "Featured",
            "Trending",
            "Owners",
            "Top-Search"
        ],
        type: 'checkbox_group',
        displayName: 'Collections',
        grid: { sm: 12 },
        deserializer: (item) => ({ ...item, value: (item.value || []).map(tag => tag.tag )}),
        serializer: (item) => (item.value || []).map(tag => ({ tag, status: true }))
    },
    {
        name: 'gallery',
        key: 'gallery',
        value: [],
        type: 'image_upload',
        limit: 10,
        displayName: 'Gallery',
        required: true,
        aspectRatio: 1.77778
    },
    {
        name: 'featured_image',
        key: 'featured_image',
        value: [],
        type: 'image_upload',
        limit: 1,
        displayName: 'Featured',
        required: true,
        aspectRatio: 1.77778
    }
]

const PropertyMeta = React.memo(({ data, fns, ...props }) => {
    return (
        <AccordionStep {...props} displayName="Property Meta">
            <Card>
                <Form {...form.layout}>
                    {data.map(field => {
                        if (field.name === 'property_type') {
                            return (
                                <Form.Item
                                    {...fns.getItemProps(field)}
                                >
                                    <Radio.Group
                                        value={field.value}
                                        options={field.options
                                            .filter(i => i.type === fns.getFieldValue('residency_type') || !fns.getFieldValue('residency_type'))}
                                        optionType={field.optionType}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            fns.setFieldValue(field, value);
                                        }}
                                    />
                                </Form.Item>
                            )
                        }

                        return fns.getFormItem(field.name)
                    })}
                </Form>
            </Card>
        </AccordionStep>
    );
});

PropertyMeta.formFields = formFields;

export default PropertyMeta;
