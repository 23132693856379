import { get } from '../../../assets/api';

const getUsers = function() {
    return new Promise((resolve, reject) => {
        get('/api/v1/seller/all', undefined, { auth: true })
            .then(async (res) => {
                if (res.status === 200) {
                    const users = await res.json();
                    resolve({
                        status: 200,
                        data: users
                    })
                } else {
                    throw new Error('Status not handled');
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
    })
}

export {
    getUsers
}
