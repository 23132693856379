import { get, post, patch, _delete } from '../../../assets/api';

export const getLocalities = () => {
    return new Promise((resolve, reject) => {
        get('/api/v1/cms/locality?page=1&limit=1000', undefined, { auth: true})
            .then(async res => {
                if (res.status === 200) {
                    const data = await res.json();
                    const localities = (data || {}).data || [];
                    
                    const cities = [...new Set(localities.map(l => l.city))];
                    
                    resolve({
                        status: 200,
                        cities: cities,
                        localities
                    });
                } else {
                    throw new Error('Status not handled');
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            })
    })
}

export const createNewLocality = (values) => {
    return new Promise((resolve, reject) => {
        post('/api/v1/cms/locality', values, { auth: true })
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve({ status: 200 })
                    window.location.reload();
                } else {
                    throw new Error('status not handled');
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            })
    })
}

export const updateLocality = (values, slug) => {
    return new Promise((resolve, reject) => {
        post(`/api/v1/cms/locality/${slug}/`, values, { auth: true})
            .then(res => {
                if (res.status === 200) {
                    resolve({ status: 200 });
                    window.location.reload();
                } else {
                    throw new Error('status not handled');
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

export const deleteLocality = (slugs) => {
    return new Promise((resolve, reject) => {
        _delete('/api/v1/cms/locality', slugs, { auth: true })
            .then(res => {
                if (res.status === 200) {
                    resolve({ status: 200 })
                    window.location.reload();
                } else {
                    throw new Error('status not handled');
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            })
    })
}

