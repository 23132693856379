import React from 'react';
// import { createBrowserHistory } from 'history';
import { 
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import Project from '../screens/project/Project';
import Properties from '../screens/properties/Properties';
import Localities from '../screens/localities/Localities';
import Builders from '../screens/builders/Builders';
import LoginPage from '../screens/login/Login';
import Amenities from '../screens/amenities/Amenities';
import UserManagement from '../screens/user-management/UserManagement';
import Blogs from '../screens/blogs/Blogs';
import LeadManagement from '../screens/lead-management/LeadManagement';
import Jobs from '../screens/jobs/Jobs';

// const history = createBrowserHistory();

const AppRouter = () => (
    <Router>
        <Switch>
            <Route path="/projects">
                <Project />
            </Route>
            <Route path="/properties/:property_type">
                <Properties />
            </Route>
            <Route path="/properties">
                <Redirect to="/properties/resale" />
            </Route>
            <Route path="/cities-and-localities">
                <Localities />
            </Route>
            <Route path="/builders">
                <Builders />
            </Route>
            <Route path="/amenities">
                <Amenities />
            </Route>
            <Route path="/leads">
                <LeadManagement />
            </Route>
            <Route path="/users">
                <UserManagement />
            </Route>
            <Route path="/blogs">
                <Blogs />
            </Route>
            <Route path="/jobs">
                <Jobs />
            </Route>
            <Route path="/">
                <LoginPage />
            </Route>
            <Redirect from="/" to="/projects" />
        </Switch>
    </Router>
);

// export { history };

export default AppRouter;
