import React from 'react';
import {
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import { Space, Button, Modal } from 'antd';
import { Link } from 'react-router-dom';

import { removeProjects } from '../../api/projects';

export const projectColumns = [
    {
        title: 'Project Name',
        key: 'project_name',
        dataIndex: 'project_name',
        render: (text) => <p style={{ textTransform: 'capitalize' }}>{text}</p>
    },
    {
        title: 'Project Type',
        key: 'project_type',
        dataIndex: 'project_type'
    },
    {
        title: 'Price',
        key: 'price',
        dataIndex: 'price',
        render: (props) => {
            return (
                <p>{props.minPrice / 100000} - {props.maxPrice / 100000} lakhs</p>
            )
        }
    },
    {
        title: 'Locality',
        key: 'locality',
        dataIndex: 'locality',
        render: (text) => <p style={{ textTransform: 'capitalize' }}>{text}</p>
    },
    {
        title: 'City',
        key: 'city',
        dataIndex: 'city'
    },
    {
        title: 'Action',
        key: 'action',
        render: (props) => {
            const slug = props.slug;


            function showPromiseConfirm() {
                Modal.confirm({
                  title: 'Do you want to delete project?',
                  icon: <ExclamationCircleOutlined />,
                  content: `This will delete the project ${props.project_name}`,
                  onOk() {
                    return removeProjects([props._id]);
                  },
                  onCancel() {},
                });
              }

            return (
                <Space>
                    <Link to={location => ({ ...location, pathname: '/projects/edit' , search: `ref=${slug}`})}>
                        <Button 
                            icon={<EditOutlined />}
                            type="primary" 
                            stlye={{ width: '128px'}}
                        >
                            Edit
                        </Button>
                    </Link>
                    <Button 
                        type="ghost" 
                        danger
                        icon={<DeleteOutlined />}
                        onClick={showPromiseConfirm}
                    >
                        Delete
                    </Button>
                </Space>
            )
        }
    }
];
