import React, { useState, useEffect } from 'react';
import {
  Form,
  Tabs,
  Input,
  Checkbox,
  Button,
  Card,
  Alert
} from 'antd';
import {
  AccordionStep,
  Upload
} from '../../../../../components';
import {
  form
} from '../../../../../assets/config';
import {
  useFormProcess
} from '../../../../../hooks';


const ProjectSpecification = React.memo(({
  value,
  fns,
  loading,
  postProject,
  ...props
}) => {

  const [formProcess, formProcessFns] = useFormProcess();

  const bhkOptions = [
    { label: '1 BHK', value: 1 },
    { label: '2 BHK', value: 2 },
    { label: '3 BHK', value: 3 },
    { label: '3.5 BHK', value: 4 },
    { label: '4 BHK', value: 5 },
    { label: '4.5 BHK', value: 6},
    { label: '5 BHK', value: 7 },
    { label: '5.5 BHK', value: 8 }
  ];

  const [mainTab, selectMainTab] = useState('1');

  const [activeConfigTab, setActiveConfigTab] = useState();

  const [plotsActiveTab, setActivePlotsTab] = useState('0');

  const onPlotsEditsChange = function(targetKey, action) {
    if (action === 'add') {
      fns.addEmptyPlotsPlan();
    }

    if (action === 'remove') {
      fns.removePlotsPlan(targetKey);
    }
  }

  useEffect(() => {
    setActiveConfigTab('0')
  }, [mainTab])

  const onConfigTabChange = function (e) {
    setActiveConfigTab(e)
  }

  const onConfigTabEditChange = function (targetKey, action) {
    if (action === 'add') {
      onConfigTabAdd();
    }

    if (action === 'remove') {
      onConfigTabRemove(targetKey);
    }
  }

  const onConfigTabAdd = function () {
    fns.addEmptyFloorPlan(mainTab);
  }

  const onConfigTabRemove = function (targetKey) {
    fns.removeFloorPlan(mainTab, targetKey);
  }

  const onSubmit = () => {
    postProject(formProcessFns);
  }

  return (
    <AccordionStep {...props} displayName="Property Specification">
      <Card loading={loading}>
        <Form {...form.layout}>
          {
            !props.isPlot ? (
              <>
                <Form.Item label="Select BHK Option">
                  <Checkbox.Group options={bhkOptions}
                    value={value.bhk}
                    onChange={fns.updateBHK}
                  />
                </Form.Item>

                <Form.Item label="Update Floor Plan">
                  <Tabs
                    activeKey={mainTab}
                    onChange={e => selectMainTab(e)}
                  >
                    {
                      bhkOptions.map(bhk => {
                        return (
                          <Tabs.TabPane tab={bhk.label} key={bhk.value}>
                            <Tabs
                              onChange={onConfigTabChange}
                              activeKey={activeConfigTab}
                              type="editable-card"
                              onEdit={onConfigTabEditChange}
                            >
                              {((value.configuration
                                .find(d => d.bhk == mainTab) || [])
                                .floor_plan || [])
                                .map((pane, paneIndex) => (
                                  <Tabs.TabPane tab={`configuration ${paneIndex + 1}`} key={paneIndex}>
                                    <Form {...form.layout}>
                                      <Form.Item label="Price">
                                        <Input
                                          value={pane.price}
                                          type="number"
                                          onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            fns.updateConfiguration(mainTab, paneIndex, 'price', value)
                                          }}
                                        />
                                      </Form.Item>
                                      <Form.Item label="Saleable Area">
                                        <Input
                                          value={pane.built_area}
                                          type="number"
                                          onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            fns.updateConfiguration(mainTab, paneIndex, 'built_area', value)
                                          }}
                                        />
                                      </Form.Item>
                                      <Form.Item label="Carpet Area">
                                        <Input
                                          value={pane.carpet_area}
                                          type="number"
                                          onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            fns.updateConfiguration(mainTab, paneIndex, 'carpet_area', value)
                                          }}
                                        />
                                      </Form.Item>
                                      <Form.Item label="Floor Plan">
                                        <Upload
                                          limit={1}
                                          fileList={pane.image}
                                          setFileList={value => fns.updateConfiguration(mainTab, paneIndex, 'image', value)}
                                        />
                                      </Form.Item>
                                    </Form>
                                  </Tabs.TabPane>
                                ))}
                            </Tabs>
                          </Tabs.TabPane>
                        );
                      })
                    }
                  </Tabs>
                </Form.Item>
              </>
            ) : (
              <Form.Item label={`Update ${props.plotText}`}>
                <Tabs
                  onChange={setActivePlotsTab}
                  activeKey={plotsActiveTab}
                  type="editable-card"
                  onEdit={onPlotsEditsChange}
                >
                  {value.plots
                    .map((pane, paneIndex) => (
                      <Tabs.TabPane tab={`configuration ${paneIndex + 1}`} key={paneIndex}>
                        <Form {...form.layout}>
                          <Form.Item label="Price">
                            <Input
                              value={pane.price}
                              type="number"
                              onChange={(e) => {
                                const value = parseInt(e.target.value);
                                fns.updatePlot(paneIndex, 'price', value)
                              }}
                            />
                          </Form.Item>
                          <Form.Item label="Saleable Area">
                            <Input
                              value={pane.built_area}
                              type="number"
                              onChange={(e) => {
                                const value = parseInt(e.target.value);
                                fns.updatePlot(paneIndex, 'built_area', value)
                              }}
                            />
                          </Form.Item>
                          <Form.Item label="Carpet Area">
                            <Input
                              value={pane.carpet_area}
                              type="number"
                              onChange={(e) => {
                                const value = parseInt(e.target.value);
                                fns.updatePlot(paneIndex, 'carpet_area', value)
                              }}
                            />
                          </Form.Item>
                          <Form.Item label="Floor Plan">
                            <Upload
                              limit={1}
                              fileList={pane.image}
                              setFileList={value => fns.updatePlot(paneIndex, 'image', value)}
                            />
                          </Form.Item>
                        </Form>
                      </Tabs.TabPane>
                    ))
                  }
                </Tabs>
              </Form.Item>
            )
          }

          <Form.Item {...form.tailLayout}>
            <Button
              type="primary"
              onClick={onSubmit}
              loading={formProcess.loading}
            >
              Save
            </Button>
          </Form.Item>
          <Form.Item {...form.tailLayout}>
            {formProcess.errorMsg && (
              <Alert message={formProcess.errorMsg} type="error" />
            )}
          </Form.Item>
        </Form>
      </Card>
    </AccordionStep>
  );
});

/***
 * 
 * bhk: [1, 2, 3]
 * configuration: [{
 *      bhk: 2,
 *      floor_plan: [{
 *          price,
 *          built_area,
 *          carpet_area,
 *      }]
 *  
 * }]
 * 
 */

function useProjectSpecification() {
  const [value, setValue] = useState({
    bhk: [],
    configuration: [],
    plots: []
  });

  function updateDataFromAPI(data) {
    setValue({
      bhk: data.bhk || [],
      plots: (data.plots || []).map(plot => {
        if (plot.location && plot.image_key) {
          return {
            ...plot,
            image: [{
              uid: plot.image_key,
              url: plot.location,
              response: {
                location: plot.location,
                image_key: plot.image_key
              }
            }]
          }
        }
        return {
          ...plot,
          image: []
        };
      }),
      configuration: (data.configuration || []).map(config => ({
        ...config,
        floor_plan: (config.floor_plan || []).map(floor_plan => {
          if (floor_plan.location && floor_plan.image_key) {
            return {
              ...floor_plan,
              image: [{
                uid: floor_plan.image_key,
                url: floor_plan.location,
                response: {
                  location: floor_plan.location,
                  image_key: floor_plan.image_key
                }
              }]
            }
          }
          return {
            ...floor_plan,
            image: []
          };
        })
      }))
    });
  }

  function updateBHK(data) {
    setValue(d => ({ ...d, bhk: data }))
  }

  function updateConfiguration(bhk, floor_plan_index, key, value) {
    bhk = parseInt(bhk);

    setValue(state => ({
      ...state,
      configuration: state.configuration.map(config => {
        if (config.bhk == bhk) {
          return {
            ...config,
            floor_plan: config.floor_plan.map((plan, index) => {
              if (index == floor_plan_index) {
                return {
                  ...plan,
                  [key]: value
                }
              }
              return plan;
            })
          }
        }
        return config;
      })
    }))
  }

  function updatePlot(plotIndex, key, value) {
    setValue(state => ({
      ...state,
      plots: (state.plots || []).map((plan, index) => {
        if (index == plotIndex) {
          return {
            ...plan,
            [key]: value
          }
        }
        return plan;
      })
    }));
  }

  function addEmptyFloorPlan(bhk) {
    bhk = parseInt(bhk);

    const no_config = value.configuration.find(c => c.bhk == bhk) === undefined;

    function getEmptyFloorPlan() {
      return {
        _id: Math.random(),
        price: 0,
        built_area: 0,
        carpet_area: 0,
        location: '',
        image_key: '',
        image: []
      };
    }

    if (no_config) {
      setValue(state => ({
        ...state,
        configuration: [
          ...state.configuration,
          {
            bhk: bhk,
            floor_plan: [getEmptyFloorPlan()]
          }
        ]
      }));

      return;
    }

    setValue(state => ({
      ...state,
      configuration: state.configuration.map(config => {
        if (config.bhk == bhk) {
          return {
            ...config,
            floor_plan: [
              ...config.floor_plan,
              getEmptyFloorPlan()
            ]
          };
        }
        return config;
      })
    }))
  }

  function addEmptyPlotsPlan() {
    function getEmptyPlan() {
      return {
        _id: Math.random(),
        price: 0,
        built_area: 0,
        carpet_area: 0,
        location: '',
        image_key: '',
        image: []
      };
    }

    setValue(state => ({
      ...state,
      plots: [...state.plots, getEmptyPlan()]
    }));
  }

  function removeFloorPlan(bhk, floor_plan_index) {
    bhk = parseInt(bhk);
    setValue(state => ({
      ...state,
      configuration: state.configuration.map(config => {
        if (config.bhk == bhk) {
          return {
            ...config,
            floor_plan: config.floor_plan.filter((plan, index) => index != floor_plan_index)
          }
        }
        return config;
      })
    }))
  }

  function removePlotsPlan(plotIndex) {
    setValue(state => ({
      ...state,
      plots: state.plots.filter((plan, index) => index != plotIndex)
    }));
  }

  function formatData() {
    return {
      ...value,
      plots: (value.plots || [])
        .filter((plan => {
          if ((plan.built_area || plan.carpet_area) && plan.price) {
            return true;
          }
          if (plan.image[0] && plan.image[0].location) {
            return true;
          }
          return false;
        })).map((plan) => ({
          built_area: plan.built_area,
          carpet_area: plan.carpet_area,
          price: plan.price,
          location: (plan.image[0] && plan.image[0].response.location) || '',
          image_key: (plan.image[0] && plan.image[0].response.image_key) || ''
        })),
      configuration: value.configuration
        .map(config => ({
          ...config,
          floor_plan: config.floor_plan
            .filter((plan => {
              if ((plan.built_area || plan.carpet_area) && plan.price) {
                return true;
              }
              if (plan.image[0] && plan.image[0].location) {
                return true;
              }
              return false;
            })).map((plan) => ({
              built_area: plan.built_area,
              carpet_area: plan.carpet_area,
              price: plan.price,
              location: (plan.image[0] && plan.image[0].response.location) || '',
              image_key: (plan.image[0] && plan.image[0].response.image_key) || ''
            }))
        }))
        .filter(configuration => configuration.floor_plan.length != 0)
    }
  }

  return [
    value,
    {
      updateDataFromAPI,
      updateBHK,
      updateConfiguration,
      addEmptyFloorPlan,
      removeFloorPlan,
      addEmptyPlotsPlan,
      removePlotsPlan,
      updatePlot,
      formatData
    }
  ]
}

ProjectSpecification.useProjectSpecification = useProjectSpecification;

export default ProjectSpecification;
