import { get, post } from '../../../../../../assets/api';

function getMeta(slug) {
    return new Promise((resolve, reject) => {
        get(`/api/v1/page-meta/locality/${slug}`)
            .then(async res => {
                if (res.status === 200 || res.status === 304) {
                    const data = await res.json();
                    resolve({
                        data: ((data.result || []).data || []),
                        status: 200
                    })
                } else if (res.status === 204) {
                    resolve({
                        status: 200,
                        data: []
                    });
                } else {
                    throw new Error(`API: status not handled ${res.status}`);
                }
            }).catch(err => {
                reject(err);
            });
    })
}

function updateMeta(payload) {
    return new Promise((resolve, reject) => {
        post('/api/v1/page-meta', payload, { auth: true })
            .then(async res => {
                if (res.status === 201 || res.status === 200) {
                    const data = await res.json();
                    resolve({
                        data: (data.result || []).data,
                        status: 200
                    })
                }
            }).catch(err => {
                reject(err);
            });
    })
}

export { 
    getMeta,
    updateMeta
};
