import React, { useState, useEffect } from 'react';
import {
    Card,
    Tabs,
    Spin
} from 'antd';
import {
    MinusCircleOutlined
} from '@ant-design/icons';
import styled from 'styled-components';

import { getAmenities, removeAmenities } from '../api';

const StyledCard = styled.div`
    padding: 1rem 1rem;
    border: 1px solid #33333311;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
`

const AmenitiesList = () => {
    const [amenities, setAmenities] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        _getAmenities();
    }, []);

    const _getAmenities = () => {
        setLoading(true);
        getAmenities()
            .then(res => {
                if (res.status === 200) {
                    let categories = { };
                    (res.data || []).forEach((amenity = {})=> {
                        if (categories[amenity.category]) {
                            categories[amenity.category] = [...categories[amenity.category], {...amenity}];
                        } else {
                            categories[amenity.category] = [{ ...amenity }];
                        }
                    });
                    setAmenities(categories);
                } else {
                    throw new Error('Status not handled');
                }
            }).catch(err => {
                
            }).finally(() => {
                setLoading(false);
            });
    }



    return (
        <Card>
            <div
                style={{
                    display: 'flex',
                    paddingLeft: '10%'
                }}
            >
                <Spin spinning={loading}>
                    <Card>
                        <Tabs tabPosition="left" style={{
                            minWidth: '100%',
                            width: 800
                        }}>
                        {
                            Object.entries(amenities).map(([ key, value]) => {
                                return (
                                    <Tabs.TabPane tab={key} key={key}>
                                        { (value || []).map((amenity = {}) => {

                                            return (
                                                <StyledCard>
                                                    {(amenity.amenity || {}).name || 'Unknown'}
                                                    <MinusCircleOutlined 
                                                        style={{ fontSize: 24 }}
                                                        onClick={() => {
                                                            setLoading(true);
                                                            removeAmenities([amenity._id])
                                                                .then(res => {
                                                                    if (res.status === 200) {
                                                                        _getAmenities()
                                                                    } else {
                                                                        throw new Error('Status not handled')
                                                                    }
                                                                }).catch(err => {

                                                                }).finally(() => {
                                                                    setLoading(false);
                                                                })
                                                        }}
                                                    />
                                                </StyledCard>
                                            )
                                        }) }
                                    </Tabs.TabPane>
                                );
                            })
                        }
                        </Tabs>
                    </Card>
                </Spin>
            </div>
        </Card>
    );
}

export default AmenitiesList;
