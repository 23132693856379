import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';

import AppRouter from './routes/AppRounter';

import './index.scss';

const App = () => {
  return <AppRouter />
};

ReactDOM.render(
  // <React.StrictMode>
    <App />,
  // </React.StrictMode>,
  document.getElementById('root')
);
