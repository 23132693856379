import React, { useState } from 'react';
import { Upload, Modal } from 'antd';
import ImgCrop from 'antd-img-crop';
import axios from 'axios';

function ImageCrop({children, ...props}) {
  if (!props.aspect) {
    return children;
  }

  return (
    <ImgCrop {...props}>
      {children}
    </ImgCrop>
  )
}

function _Upload(props) {
    const [previewVisible, setPreviewVisibile] = useState();
    const [previewTitle, setPreviewTitle] = useState();
    const [previewImage, setPreviewImage] = useState();

    const onCancel = () => setPreviewVisibile(false)

    const onPreview = async file => {
        console.log(file);
        setPreviewImage(file && file.response && file.response.location);
        setPreviewVisibile(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const onChange = ({ fileList: newFileList }) => {
        props.setFileList(newFileList);
    };

    const aspectRatio = props.aspectRatio || null;

    return (
        <>
            <ImageCrop grid aspect={aspectRatio}>
                <Upload
                    action={"https://backend.investoxpert.com/api/v1/image-upload/"}
                    listType="picture-card"
                    fileList={props.fileList}
                    headers={{
                      Authorization: `${localStorage.getItem('token')}`,
                    }}
                    onChange={onChange}
                    onPreview={onPreview}
                    customRequest={({
                        action,
                        data,
                        file,
                        headers,
                        onError,
                        onProgress,
                        onSuccess,
                     }) => {
                        const formData = new FormData();
                        console.log(data);
                        if (data) {
                          Object.keys(data).forEach(key => {
                            formData.append(key, data[key]);
                          });
                        }
                        formData.append('image', file);

                        console.log(file);

                        console.log(headers);

                        axios
                          .post(action, formData, {
                            headers,
                            onUploadProgress: ({ total, loaded }) => {
                              onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file);
                            },
                          })
                            .then(({ data: response }) => {
                                if (response.result && response.result[0]) {
                                    onSuccess(response.result[0], file);
                                } else {
                                    onError(new Error('Something went wrong while uploading file!'));
                                }
                            })
                            .catch((err) => {
                              console.error(err);
                              onError(err);
                            });
                    
                        return {
                          abort() {
                            console.log('upload progress is aborted.');
                          },
                        };
                    }}
                >
                   {(props.fileList.length < (props.limit || 10)) ? '+ Upload' : null}
                </Upload>
            </ImageCrop>
            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={onCancel}
            >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </>
    );
}

export default _Upload;
