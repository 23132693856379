import querystring from 'querystring';
const BASE_URL = 'https://backend.investoxpert.com';

const getAuthHeaders = () => {
    return {
        Authorization: localStorage.getItem('token')
    };
}

const post = (endpoint, data = {}, options = {}) => {

    let headers = {};

    if (options.auth) {
        headers = {
            ...getAuthHeaders()
        }
    }

    return fetch(`${BASE_URL}${endpoint}`, {
        mode: 'cors',
        method: 'post',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...headers
        },
        body: JSON.stringify(data)
    });
}

const _delete = (endpoint, data = {}, options = {}) => {

    let headers = {};

    if (options.auth) {
        headers = {
            ...getAuthHeaders()
        }
    }

    return fetch(`${BASE_URL}${endpoint}`, {
        mode: 'cors',
        method: 'delete',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...headers
        },
        body: JSON.stringify(data)
    });
}


const get = (endpoint, data = undefined, options = {}) => {
    let queryURL = '';
    if (data) {
        queryURL = `?${querystring.stringify(data)}`;
    }

    let headers = {};

    if (options.auth) {
        headers = {
            ...getAuthHeaders()
        }
    }

    return fetch(`${BASE_URL}${endpoint}${queryURL}`, {
        headers
    });
}


const patch = (endpoint, data = {}, options = {}) => {

    let headers = {};

    if (options.auth) {
        headers = {
            ...getAuthHeaders()
        }
    }

    return fetch(`${BASE_URL}${endpoint}`, {
        mode: 'cors',
        method: 'patch',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...headers
        },
        body: JSON.stringify(data)
    });
}

export { post, get, _delete, patch };
