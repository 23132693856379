import React, { useState, useCallback, useEffect } from 'react';
import {
    Alert,
    Button,
    Card,
    Dropdown,
    Form,
    Menu,
    Spin,
    Space,
} from 'antd';
import { useHistory } from 'react-router-dom';
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';

import { form } from '../../../../assets/config';
import { useCustomForm, useFormProcess, useQuery } from '../../../../hooks';
import { saveJob, getJob } from '../../api';

const EditJobs = () => {
    const query = useQuery();
    const history = useHistory();
    const [data, fns] = useCustomForm(formFields);
    const [meta, setMeta] = useState({ publishStatus: false, loading: false, error: false, _id: null });
    const [saveStatus, saveStatusFns] = useFormProcess();
    const [loadStatus, loadStatusFns] = useFormProcess();

    const _getJob = useCallback(() => {
        let ref = query.get('ref');
        if (ref === 'new') {
            return;
        }
        loadStatusFns.setLoading(true);
        getJob(ref)
            .then(res => {
                if (res.status === 200) {
                    fns.updateData({ ...res.data, locations: ((res.data || {}).locations || []).join(',') })
                    setMeta(d => ({ ...d, publishStatus: res.data.publish, _id: res.data._id }))
                    loadStatusFns.setLoading(false);
                } else {
                    throw new Error('status not handled');
                }
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                loadStatusFns.setLoading(false);
            })
    }, []);

    useEffect(() => {
        _getJob();
    }, [_getJob])

    function _saveBlog() {
        const [values, errors] = fns.serialize();
        if (errors.length > 0) {
            saveStatusFns.setErrorMessage(errors[0].help);
            return;
        }

        if(values.min > values.max) {
            saveStatusFns.setErrorMessage('Please make sure max experience is less then min experience');
            return;
        }
        
        if (values.min < 0) {
            saveStatusFns.setErrorMessage('Please make sure min is zero or greater then zero');
            return;
        }

        if (values.max < 0) {
            saveStatusFns.setErrorMessage('Please make sure max is zero or greater then zero');
            return;
        }

        if (values.min > 100) {
            saveStatusFns.setErrorMessage('Please make sure min is less then 100');
            return;
        }

        if (values.max < 100) {
            saveStatusFns.setErrorMessage('Please make sure max is less then 100');
            return;
        }
        
        saveStatusFns.setLoading(true);

        const payload = {
            ...values,
            locations: values.locations.split(','),
            _id: meta._id
        };

        saveStatusFns.setErrorMessage('');
        saveJob(payload)
            .then(res => {
                if(res.status === 200) {
                    if (!meta._id || res._id) {
                        saveStatusFns.setErrorMessage('');
                        setMeta(d => ({
                            ...d,
                            _id: res._id
                        }));
                        history.push(`/jobs/edit?ref=${res._id}`);
                        window.location.reload();
                    }
                } else if (res.status === 409) {
                    if (res.msg) {
                        saveStatusFns.setErrorMessage(res.msg);
                    } else {
                        saveStatusFns.setErrorMessage('Title already exists.');
                    }
                } else if (res.status === 500) {
                    if (res.msg) {
                        saveStatusFns.setErrorMessage(res.msg)
                    } else {
                        throw new Error('Please try again');
                    }
                } else {
                    throw new Error('status not handled');
                }
            }).catch(error => {
                console.error(error);
                saveStatusFns.setErrorMessage('Please try again');
            }).finally(() => {
                saveStatusFns.setLoading(false);
            })
    }

    function togglePublish(publish) {
        const payload = {
            _id: meta._id,
            publish: publish
        };

        setMeta(d => ({ ...d, loading: true }));
        saveJob(payload)
            .then(res => {
                if(res.status === 200) {
                    setMeta(d => ({ ...d, publishStatus: publish }));
                } else {
                    throw new Error('status not handled');
                }
            }).catch(error => {
                console.error(error);
                setMeta(d => ({ ...d, error: true }));
            }).finally(() => {
                setMeta(d => ({ ...d, loading: false }));
            })
    }

    function handleMenuClick(e) {
        togglePublish(e.key==='true');
    }

    const menu = () => {
        
        
        return (
            <Menu onClick={handleMenuClick}>
                {
                    meta.publishStatus ? (
                        <Menu.Item key={false}>Un-Publish</Menu.Item>
                    ) : (
                        <Menu.Item key={true}>Publish</Menu.Item>
                    )
                }
            </Menu>
        );
    }


    let PublishIcon = () => null;

    if (meta.publishStatus) {
        PublishIcon = CheckCircleOutlined;
    }
    
    if (meta.error) {
        PublishIcon = ExclamationCircleOutlined;
    }

    return (
        <>
            {
                meta.publishStatus && (
                    <Alert 
                        type="info"
                        description={
                            <>
                                <a href={`https://www.investoxpert.com/carrers/${meta._id}`} target="_blank" rel="noopener noreferrer">
                                    Live Link: https://www.investoxpert.com/carrers/{meta._id}
                                </a>
                            </>
                        }
                        message={"Status Live"}
                        showIcon 
                    />
                )
            }
            <Card 
                bodyStyle={{ padding: 16 }}
                style={{ marginBottom: 4, display: 'flex', justifyContent: 'flex-end' }}
            >
                <Space>
                    <Button type="primary" style={{ width: 120 }}
                        onClick={_saveBlog}
                        loading={saveStatus.loading}
                    >
                        Save
                    </Button>
                    {
                        meta._id && (
                            <Dropdown.Button
                                overlay={menu}
                                buttonsRender={([left, right]) => [
                                    left,
                                    React.cloneElement(right, { loading: meta.loading })
                                ]}
                                onClick={() => togglePublish(!meta.publishStatus)}
                            >
                                {
                                    meta.publishStatus ? (
                                        <Space>
                                            <PublishIcon /> Published
                                        </Space>
                                    ) : 'Not Published'
                                }
                            </Dropdown.Button>
                        )
                    }
                </Space>
            </Card>
            {
                saveStatus.errorMsg && (
                    <Alert
                        type="error"
                        message={saveStatus.errorMsg}
                        showIcon 
                    />
                )
            }
            <Spin spinning={loadStatus.loading}>
                <Card>
                    <Form {...form.layout}>
                        {
                            data.map(field => {
                                return fns.getFormItem(field.name);
                            })
                        }
                    </Form>
                </Card>
            </Spin>
        </>
    )
}


const formFields = [
    {
        name: 'title',
        key: 'title',
        displayName: 'Title',
        type: 'text',
        required: true,
        value: ''
    },
    {
        name: 'category',
        key: 'category',
        displayName: 'Category',
        defaultErrorMessage: 'Category: Ex: Development, Sales, Marketing',
        type: 'text',
        required: true,
        value: ''
    },
    {
        name: 'min_experience',
        key: 'min_experience',
        displayName: 'Min Experience',
        type: 'number',
        min: 0,
        required: true,
        value: 0
    },
    {
        name: 'max_experience',
        key: 'max_experience',
        displayName: 'Max Experience',
        type: 'number',
        max: 100,
        required: true,
        value: 0
    },
    {
        name: 'locations',
        key: 'locations',
        displayName: 'Locations',
        defaultErrorMessage: 'Enter locations separed by commas, Ex: "pune, noida"',
        type: 'text',
        required: true,
        value: ''
    },
    {
        name: 'description',
        key: 'description',
        displayName: 'Description',
        type: 'rich_text',
        required: true,
        value: ''
    }
];

export default EditJobs;