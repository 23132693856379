import React, { useState, useEffect } from 'react';
import {
    Card,
    Steps,
    Space,
    Menu,
    Dropdown,
    Alert
} from 'antd';
import {
    ExclamationCircleOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';

import { useQuery } from '../../../../hooks';
import { hooks as localitiesHooks } from '../../../localities';
import { hooks as builderHooks } from '../../../builders';

import { getProject, updateProject } from '../../api/projects';

import {
    ProjectSpecification,
    ProjectMeta,
    ProjectLocation,
    ProjectBasicInformation,
    ProjectBanks,
    ProjectAmenities,
    ProjectSchema
} from './components';
import { openSuccess, openError } from '../../../../assets/fns';

const EditProject = () => {
    const query = useQuery();
    const [current, setCurrent] = useState();
    const [loading, setLoading] = useState();

    const [_id, setId] = useState();
    const [cities, localities] = localitiesHooks.useLocalities();
    const builders = builderHooks.useBuilders();

    const [projectSpcValue, projectSpcFns] = ProjectSpecification.useProjectSpecification();
    const [projectMetaValue, projectMetaFns] = ProjectMeta.useProjectMeta();
    const [projectLocationValue, projectLocationFns] = ProjectLocation.useLocation();
    const [projectBanksValue, projectBanksFns] = ProjectBanks.useProjectBanks();
    const [projectSchemaValue, projectSchemaFns] = ProjectSchema.useProjectSchema();
    const [amenitiesData, amenitiesFns] = ProjectAmenities.useProjectAmenities();


    const [
        projectBasicInformationValue,
        projectBasicInformationFns
    ] = ProjectBasicInformation.useProjectBasicInformation();

    const [publish, setPublish] = useState({
        loading: false,
        status: false,
        error: false
    }) 


    const postProject = (fns) => {
        return (formProcessFns) => {

            if (!projectMetaValue.title) {
                formProcessFns.setErrorMessage('Please Add Project Title');
                return;
            }

            formProcessFns.setLoading(true);
            formProcessFns.setErrorMessage('');

            updateProject(fns.formatData(), _id)
                .then(res => {
                    if(res.status === 200) {
                        formProcessFns.setErrorMessage('');
                        if(res._id) {
                            setId(res._id);
                        }
                        openSuccess('Project Updated');
                    } else if (res.status === 409 || res.status === 500) {
                        formProcessFns.setErrorMessage(res.error);
                    } else {
                        throw new Error('API Error');
                    }
                }).catch(error => {
                    console.log(error);
                    openError('Error, while saving project');
                    formProcessFns.setErrorMessage('Please Try Again');
                }).finally(() => {
                    formProcessFns.setLoading(false);
                });
        }
    }

    const onPublish = (status) => {
        setPublish(s => ({
            ...s,
            error: false,
            loading: true
        }));
        updateProject({ publish: status }, _id)
            .then(res => {
                if (res.status === 200) {
                    setPublish(s => ({
                        error: false,
                        status: status,
                        loading: false
                    }));
                    if (res._id) {
                        setId(res._id)
                    }
                } else {
                    throw new Error('');
                }
            }).catch(error => {
                setPublish(s => ({
                    ...s,
                    loading: false,
                    error: true
                }))
            })
    }

    useEffect(() => {
        const ref = query.get('ref');

        if (ref === 'new') {
            return;
        }

        setLoading(true);
        getProject(ref)
            .then(res => {
                if (res.status === 200) {
                    const { data = {} } = res;


                    setId(data._id);
                    projectMetaFns.updateDataFromAPI(data);
                    projectSpcFns.updateDataFromAPI(data);
                    projectLocationFns.updateDataFromAPI(data);
                    projectBasicInformationFns.updateDataFromAPI(data);
                    projectBanksFns.updateDataFromAPI(data);
                    amenitiesFns.updateDataFromAPI(data);
                    projectSchemaFns.updateDataFromAPI(data);
                    setPublish(s => ({
                        ...s,
                        status: data.publish
                    }))

                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            })
    }, []);

    const LivePageLink = (
        <Space>
            Live on link:
            <a
                href={`https://www.investoxpert.com/project/${projectMetaValue.slug}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                https://www.investoxpert.com/project/{projectMetaValue.slug}
            </a>

        </Space>
    );

    let PublishIcon = () => null;
    
    if (publish.status) {
        PublishIcon = CheckCircleOutlined;
    }
    
    if (publish.error) {
        PublishIcon = ExclamationCircleOutlined;
    }

    function handleMenuClick(e) {
        onPublish(e.key==='true');
    }

    const menu = () => {
        
        
        return (
            <Menu onClick={handleMenuClick}>
                {
                    publish.status ? (
                        <Menu.Item key={false}>Un-Publish</Menu.Item>
                    ) : (
                        <Menu.Item key={true}>Publish</Menu.Item>
                    )
                }
            </Menu>
        );
    }

    return (
        <>
            {
                publish.status && (
                    <Alert 
                        type="info" 
                        description={LivePageLink}
                        message={"Status Live"}
                        showIcon 
                    />
                )
            }
            {
                _id && (
                    <Card 
                            bodyStyle={{ padding: 16 }}
                            style={{ marginBottom: 4 }}
                    >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <Space>
                                <Dropdown.Button 
                                    overlay={menu}
                                    buttonsRender={([left, right]) => [
                                        left, 
                                        React.cloneElement(right, { loading: publish.loading })
                                    ]}
                                    onClick={() => onPublish(!publish.status)}
                                >
                                    { 
                                        publish.status ? (
                                            <Space> 
                                                <PublishIcon /> 
                                                Published 
                                            </Space> 
                                        ) : 'Not Published'
                                    }
                                </Dropdown.Button>
                            </Space>
                        </div>
                    </Card>
                )
            }
            <Card>
                <Steps
                    direction="vertical"
                    current={current}
                    onChange={setCurrent}
                >

                    <ProjectMeta
                        loading={loading}
                        value={projectMetaValue}
                        fns={projectMetaFns}
                        postProject={postProject(projectMetaFns)}
                    />

                    <ProjectLocation
                        loading={loading}
                        value={projectLocationValue}
                        fns={projectLocationFns}
                        cities={cities}
                        localities={localities}
                        postProject={postProject(projectLocationFns)}
                        />

                    <ProjectBasicInformation
                        loading={loading}
                        value={projectBasicInformationValue}
                        fns={projectBasicInformationFns}
                        builders={builders}
                        postProject={postProject(projectBasicInformationFns)}
                    />

                    <ProjectAmenities 
                        loading={loading}
                        value={amenitiesData}
                        fns={amenitiesFns}
                        postProject={postProject(amenitiesFns)}
                    />

                    <ProjectSpecification 
                        loading={loading}
                        value={projectSpcValue}
                        fns={projectSpcFns}
                        isPlot={projectMetaValue.property_type === 'Plot' || projectMetaValue.residency_type === 'Commercial'}
                        plotText={projectMetaValue.property_type}
                        postProject={postProject(projectSpcFns)}
                    />

                    {/* <ProjectBanks
                        loading={loading}
                        value={projectBanksValue}
                        fns={projectBanksFns}
                        postProject={postProject(projectBanksFns)}
                    /> */}

                    <ProjectSchema
                        loading={loading}
                        value={projectSchemaValue}
                        fns={projectSchemaFns}
                        postProject={postProject(projectSchemaFns)}
                    />

                </Steps>
            </Card>
        </>
    );
}

export default EditProject;
