import { get, _delete } from '../../../assets/api';

const getLeads = (payload) => {
    return new Promise((resolve, reject) => {
        get('/api/v1/contact-us', payload, { auth: true})
            .then(async res => {
                if (res.status === 200 || res.status === 304) {
                    const data = await res.json();

                    resolve({
                        status: 200,
                        data: data.data,
                        count: data.count
                    });
                
                }  else if (res.status === 401) {
                    localStorage.clear();
                    window.location.reload();
                } else {
                    throw new Error('Status not handled');
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            })
    })
}

const deleteLeads = (payload) => {
    return new Promise((resolve, reject) => {
        _delete('/api/v1/contact-us', payload, { auth: true })
            .then(async res => {
                if (res.status === 200) {
                    resolve({ 200: true });
                    window.location.reload();
                } else {
                    throw new Error('status not handled');
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
    })
}

export {
    getLeads,
    deleteLeads
}