import { 
    AppstoreAddOutlined,
    FolderAddOutlined
} from '@ant-design/icons';
import React from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    useHistory
} from 'react-router-dom';
import { Typography, Row, Button, Space, Col, Divider } from 'antd'

import { PageContainer } from '../../components';

import { PropertyList, EditProperty } from './frames';

const Property = () => {
    const { path, params, url } = useRouteMatch();
    const history = useHistory();

    if (!localStorage.getItem('token')) {
        history.push('/login')
    }

    const propertyType = params.property_type;

    if(!['resale', 'rental'].includes(propertyType)) {
        history.push('properties');
    }

    return (
        <PageContainer>
            <div style={{ background: '#fff' }}>
                <Row gutter={[0, 0]}>
                    <Col offset="0" span="22" style={{ marginLeft: 16 }}>
                        <Typography.Title level={1}> Properties </Typography.Title>
                    </Col>
                </Row>
                <Space
                    size="small"
                    style={{
                        margin: '0 1.2rem 0 1.2rem',
                        height: 64,
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                    align="center">
                    <Space>
                        <Button 
                            onClick={() => {
                                history.push('/properties/resale');
                                window.location.reload();
                            }}
                            type = { propertyType === 'resale' ? 'primary' : 'default' }
                            >
                            Resale Properties
                        </Button>
                        <Button 
                            onClick={() => {
                                history.push('/properties/rental');
                                window.location.reload();
                            }}
                            type = { propertyType === 'rental' ? 'primary' : 'default' }
                        >
                            Rental Properties
                        </Button>
                    </Space>
                    <div>
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => {
                                    history.push(`${url}/edit?ref=new`);
                                    window.location.reload();
                                }} 
                                icon={<FolderAddOutlined />}
                                >Add Property</Button>
                            <Button type="dashed" icon={<AppstoreAddOutlined />}>
                                Add Multiple Properties
                            </Button>
                        </Space>
                    </div>
                </Space>
            </div>
            <Divider style={{ borderTop: '1px solid #eee', margin: 0 }}/>
            <div style={{ margin: '1rem 1rem' }}>
                <Switch>
                    <Route path={`${path}/edit`}>
                        <EditProperty />
                    </Route>
                    <Route exact path={`${path}`}>
                        <PropertyList type={propertyType} />
                    </Route>
                </Switch>
            </div>
        </PageContainer>
    )
};

export default Property;
