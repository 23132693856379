import React from 'react';
import { Form, Card } from 'antd';

import { form, banks } from '../../../../../assets/config';
import { AccordionStep } from '../../../../../components';

const formFields = [
    {
        name: 'banks',
        key: 'banks',
        options: banks,
        type: 'checkbox_group',
        displayName: 'EMI Banking Information',
        propertyType: 'resale',
        grid: { lg: 12 }
    }
]

const PropertyBanks = React.memo(({ data, fns, propertyType, ...props }) => {
    return (
        <AccordionStep {...props} displayName="Banking Information">
            <Card>
                <Form {...form.layout}>
                    {data.filter(f => {
                        if (f.propertyType === propertyType || f.propertyType == null) {
                            return true;
                        }
                        return false;
                    }).map(field => {
                        return fns.getFormItem(field.name)
                    })}
                </Form>
            </Card>
        </AccordionStep>
    )
});

PropertyBanks.formFields = formFields;

export default PropertyBanks;
