import { get, post, _delete } from '../../../assets/api';

export const getBuilders = (value = undefined) => {
    return new Promise((resolve, reject) => {
        get('/api/v1/cms/builders', value)
            .then(async res => {
                if (res.status === 200 || res.status === 412) {
                    const data = await res.json();
                    resolve({
                        status: 200,
                        data: ((data || {}).data || []).map(d => ({
                            name: d.name || '',
                            established: d.established || '',
                            meta_title: d.meta_title || '',
                            meta_description: d.meta_description,
                            image: d.image ? [{
                                url: d.image.location,
                                uid: d.image.image_key,
                                response: {
                                    location: d.image.location,
                                    image_key: d.image.image_key
                                } 
                            }] : [],
                            about: d.about || '',
                            _id: d._id,
                            slug: d.slug
                        }))
                    });
                } else if (res.status === 500) {
                    resolve({
                        status: 500
                    });
                } else {
                    throw new Error('Status not handled');
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            })
    })
}

export const createNewBuilder = (values) => {
    return new Promise((resolve, reject) => {
        post('/api/v1/cms/builders', values, { auth: true })
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve({ status: 200 });
                    window.location.reload();
                } else {
                    throw new Error('status not handled');
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            })
    })
}

export const updateBuilder = (values, slug) => {
    return new Promise((resolve, reject) => {
        post(`/api/v1/cms/builders/${slug}/`, values, { auth: true})
            .then(res => {
                if (res.status === 200) {
                    resolve({ status: 200 });
                    window.location.reload();
                } else {
                    throw new Error('status not handled');
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

export const deleteBuilder = (ids) => {
    return new Promise((resolve, reject) => {
        _delete('/api/v1/cms/builders', ids, { auth: true })
            .then(res => {
                if (res.status === 200) {
                    resolve({ status: 200 });
                    window.location.reload();
                } else {
                    throw new Error('status not handled');
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            })
    })
}

