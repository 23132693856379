import { post } from '../../assets/api';

const login = (email, password) => {
    return new Promise(( resolve, reject ) => {
        post('/api/v1/auth/login', { email, password })
            .then(async res => {
                if (res.status === 200) {
                    const data = await res.json();

                    resolve({
                        status: 200,
                        token: data.token,
                        username: email
                    });
                } else if (res.status === 400) {
                    const data = await res.json();
                    resolve({
                        status: 400,
                        error: data.error
                    });
                }else {
                    throw new Error('API Error');
                }
            }).catch(error => {
                reject(error);
            })
    })
}

export { login };
